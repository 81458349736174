<app-header></app-header>
<section [ngClass]="[publicformVisibility == true ? 'banner bg' : 'banner bg app--banner' ]" >
    <h2>Find Your <strong>Permanent Accommodation</strong></h2>
    <div class="app_btn" *ngIf="publicformVisibility == true">
        <a href="#" class="mr-2"><img src="assets/images/btn-appstore.svg"></a>
        <a href="#"><img src="assets/images/btn-googleplay.svg"></a>
    </div>
</section>
<div class="heading" *ngIf="publicformVisibility == true" >My Social Housing</div>
<section class="form_box" *ngIf="publicformVisibility == true">
<div class="container">
<div class="form_inner">
            <form [formGroup]="updateProfileForm" (ngSubmit)="onSubmit(true)">
                <div class="row">
                    <h3>My Social Housing Application Form</h3>
                    <div class="col-sm-6 wh_input">
                        <label>First Name <span class="danger">*</span> </label>
                        <input type="text" formControlName="firstName" class="form-control" placeholder="First Name">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.firstName.dirty || updateProfileForm.controls.firstName.touched) && updateProfileForm.controls.firstName.errors">
                            <small *ngIf="updateProfileForm.controls.firstName.errors.required">First Name is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Last Name <span class="danger">*</span></label>
                        <input  type="text" formControlName="lastName" class="form-control" placeholder="Last Name">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.lastName.dirty || updateProfileForm.controls.lastName.touched) && updateProfileForm.controls.lastName.errors">
                            <small *ngIf="updateProfileForm.controls.lastName.errors.required">Last Name is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Email <span class="danger">*</span></label>
                        <input  type="email" formControlName="email" class="form-control" placeholder="email">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.email.dirty || updateProfileForm.controls.email.touched) && updateProfileForm.controls.email.errors">
                            <small *ngIf="updateProfileForm.controls.email.errors.required">Email is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Date of Birth <span class="danger">*</span></label>
                        <input [maxDate]="currentDate"   formControlName="dateOfBirth" id="birthday" class="form-control" placeholder="Date of birth" name="birthday" bsDatepicker (keydown)="onBsKeyPress($event)" [bsConfig]="{ containerClass: 'theme-blue' , showWeekNumbers: false , dateInputFormat: 'DD/MM/YYYY', rangeInputFormat: 'DD/MM/YYYY', returnFocusToInput: true }" autocomplete="off">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.dateOfBirth.dirty || updateProfileForm.controls.dateOfBirth.touched) && updateProfileForm.controls.dateOfBirth.errors">
                            <small *ngIf="updateProfileForm.controls.dateOfBirth.errors.required">DOB is required</small>
                        </div>
                    </div>
                    <div class="col-sm-12 wh_input">
                        <label>Address <span class="danger">*</span></label>
                        <textarea  class="form-control" formControlName="address" placeholder="Address"></textarea>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.address.dirty || updateProfileForm.controls.address.touched) && updateProfileForm.controls.address.errors">
                            <small *ngIf="updateProfileForm.controls.address.errors.required">Address is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Number of years at address <span class="danger">*</span></label>
                        <input  type="number" min="0" class="form-control" formControlName="noOfYearsAtAdress">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.noOfYearsAtAdress.dirty || updateProfileForm.controls.noOfYearsAtAdress.touched) && updateProfileForm.controls.noOfYearsAtAdress.errors">
                            <small *ngIf="updateProfileForm.controls.noOfYearsAtAdress.errors.required">Please enter the details</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Number of months at address <span class="danger">*</span></label>
                        <input  type="number" min="0" class="form-control" formControlName="noOfMonthsAtAdress">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.noOfMonthsAtAdress.dirty || updateProfileForm.controls.noOfMonthsAtAdress.touched) && updateProfileForm.controls.noOfMonthsAtAdress.errors">
                            <small *ngIf="updateProfileForm.controls.noOfMonthsAtAdress.errors.required">Please enter the details</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Postcode <span class="danger">*</span></label>
                        <input  type="text" class="form-control" formControlName="postCode" placeholder="Postcode">
                        <!-- {{updateProfileForm?.controls?.postCode?.errors | json}} -->
                        <div class="has-error" *ngIf="(updateProfileForm.controls.postCode.dirty || updateProfileForm.controls.postCode.touched) && updateProfileForm.controls.postCode.errors">
                            <div *ngIf="updateProfileForm.controls.postCode.errors.postalCodeValidator" [innerHTML]="updateProfileForm.controls.postCode.errors.postalCodeValidator"></div>
                        </div>
                    </div>

                    <div class="spacer"></div>
                    <h3>Address History 2 <span class="danger">*</span></h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory2" placeholder="Address History 2"></textarea>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.addressHistory2.dirty || updateProfileForm.controls.addressHistory2.touched) && updateProfileForm.controls.addressHistory2.errors">
                            <small *ngIf="updateProfileForm.controls.addressHistory2.errors.required">Address History 2 is required</small>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    <h3>Address History 3 <span class="danger">*</span></h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory3" placeholder="Address History 3"></textarea>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.addressHistory3.dirty || updateProfileForm.controls.addressHistory3.touched) && updateProfileForm.controls.addressHistory3.errors">
                            <small *ngIf="updateProfileForm.controls.addressHistory3.errors.required">Address History 3 is required</small>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    <h3>Address History 4 <span class="danger">*</span></h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory4" placeholder="Address History 4"></textarea>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.addressHistory4.dirty || updateProfileForm.controls.addressHistory4.touched) && updateProfileForm.controls.addressHistory4.errors">
                            <small *ngIf="updateProfileForm.controls.addressHistory4.errors.required">Address History 4 is required</small>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    <h3>Address History 5 <span class="danger">*</span></h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory5" placeholder="Address History 5"></textarea>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.addressHistory5.dirty || updateProfileForm.controls.addressHistory5.touched) && updateProfileForm.controls.addressHistory5.errors">
                            <small *ngIf="updateProfileForm.controls.addressHistory5.errors.required">Address History 5 is required</small>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    
                    <div class="col-sm-6 wh_input" *ngIf="referenceAgents && referenceAgents.length">
                        <label>Referring Agent <span class="danger">*</span></label>
                        <input
                                type="text" class="form-control"
                                formControlName="referringAgent"
                                placeholder="Referring Agent"
                                [typeahead]="referenceAgents"
                                typeaheadOptionField="name"
                        >
                        <div class="has-error" *ngIf="(updateProfileForm.controls.referringAgent.dirty || updateProfileForm.controls.referringAgent.touched) && updateProfileForm.controls.referringAgent.errors">
                            <small *ngIf="updateProfileForm.controls.referringAgent.errors.required">Referring Agent is required</small>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    <h3>Landlord Details</h3>
                    <div class="col-sm-6 wh_input">
                        <label>Landlord Name <span class="danger">*</span></label>
                        <input type="text" class="form-control" formControlName="landlordName" placeholder="Landlord name">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.landlordName.dirty || updateProfileForm.controls.landlordName.touched) && updateProfileForm.controls.landlordName.errors">
                            <small *ngIf="updateProfileForm.controls.landlordName.errors.required">Landlord name is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Landlord phone number <span class="danger">*</span></label>
                        <input type="text" class="form-control" formControlName="landlordPhoneNumber" placeholder="Phone number">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.landlordPhoneNumber.dirty || updateProfileForm.controls.landlordPhoneNumber.touched) && updateProfileForm.controls.landlordPhoneNumber.errors">
                            <small *ngIf="updateProfileForm.controls.landlordPhoneNumber.errors.required">Landlord Phone number is required</small>
                            <small *ngIf="updateProfileForm.controls.landlordPhoneNumber.errors.pattern">Enter valid Phone Number</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Landlord Email</label>
                        <input type="text" class="form-control" formControlName="landlordEmail" placeholder="Landlord email">
                    </div>
                    <div class="spacer"></div>
                    <h3>Family Composition</h3>
                    <div class="col-sm-6 wh_input">
                        <label>First Name</label>
                        <input type="text" class="form-control" formControlName="firstNameFC" placeholder="First name">
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Last Name</label>
                        <input type="text" class="form-control" formControlName="lastNameFC" placeholder="Last name">
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Date of Birth</label>
                        <input [maxDate]="currentDate"  id="birthday" formControlName="dateOfBirthFC" class="form-control" placeholder="Date of birth" name="birthday" bsDatepicker (keydown)="onBsKeyPress($event)" [bsConfig]="{containerClass: 'theme-blue' , showWeekNumbers: false , rangeInputFormat: 'DD/MM/YYYY'}" autocomplete="off">
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Relationship to you</label>
                        <ng-select [searchable]=false  class="form-control" [items]="relationshipOption" formControlName="relationshipToYouFC" placeholder="Select relationship"> </ng-select>
                    </div>
                    <h3 class="color_green">Additional Family Member </h3>
                    <!-- <div class="col-sm-6 wh_input">
                        <h5>
                            <span style="cursor: pointer;" class="btn btn-bordered"> Add Family Member
                            </span>
                            <button type="button" *ngIf="showFamilyDetails && (updateProfileForm.get('additionalMembers').length < 3)" class="ml-2 addBtn" (click)="addItem()"> +</button>
                        </h5>
                    </div> -->
                </div>
                <p>
                    <a class="btn" style="border-radius: 20px;color: #474747;border: 1px solid #3D868D;" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        Add Details of Family Member&nbsp;&nbsp;  <i class="fa fa-arrow-circle-down"></i>
                    </a>
                </p>
                <div class="collapse" id="collapseExample">
                    <div>
                        <div formArrayName="additionalMembers" *ngFor="let item of updateProfileForm.get('additionalMembers')['controls']; let i = index;">
                            <div class="row" [formGroupName]="i">
                                <div class="col-sm-12">
                                    <ng-container [ngSwitch]="i">
                                        <h5  *ngSwitchCase="0">Second </h5>
                                        <h5  *ngSwitchCase="1">Third </h5>
                                        <h5  *ngSwitchCase="2">Fourth </h5>
                                        <h5  *ngSwitchCase="3">Fifth </h5>
                                        <h5  *ngSwitchCase="4">Sixth </h5>
                                        <h5 *ngSwitchDefault>-</h5>
                                    </ng-container>
                                </div>
                                <div class="col-sm-6 wh_input">
                                    <label>First Name</label>
                                    <input type="text" formControlName="firstName" class="form-control" placeholder="First name">
                                    <div>
                                    </div>
                                </div>
                                <div class="col-sm-6 wh_input">
                                    <label>Last Name</label>
                                    <input type="text" formControlName="lastName" class="form-control" placeholder="Last name">
                                </div>
                                <div class="col-sm-6 wh_input">
                                    <label>Date of Birth</label>
                                    <div>
                                        <input [maxDate]="currentDate"   bsDatepicker (keydown)="onBsKeyPress($event)" [bsConfig]="{containerClass: 'theme-blue' , showWeekNumbers: false , rangeInputFormat: 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY'}" formControlName="dateOfBirth" [id]="'birthday'+i" class="form-control"
                                               placeholder="Date of birth" name="birthday" autocomplete="off">
                                        <!-- <input   formControlName="dateOfBirth" [id]="'birthday'+i" class="form-control" class="form-control" placeholder="Date of birth" name="birthday" bsDatepicker [bsConfig]="{containerClass: 'theme-blue' , showWeekNumbers: false , dateInputFormat: 'DD/MM/YYYY', rangeInputFormat: 'DD/MM/YYYY'}"> -->
                                    </div>
                                </div>
                                <div class="col-sm-6 wh_input">
                                    <label>Relationship to you</label>
                                    <ng-select [searchable]=false   class="form-control" [items]="relationshipOption" formControlName="relationshipToYou" placeholder="Select relationship"> </ng-select>
                                </div>
                                <div class="col-sm-6 wh_input">
                                    <div class="alignImage">
                                        <div class="familyDoc">
                                            <img [src]="checkUserMemberDetails(updateProfileForm.value.additionalMembers, i ,'imgIdProofMember')?checkUserMemberDetails(updateProfileForm.value.additionalMembers, i ,'imgIdProofMember'):defaultImage" (click) = "openImage(template1 ,checkUserMemberDetails(updateProfileForm.value.additionalMembers, i ,'imgIdProofMember'))">
                                        </div>
                                        <div class="delteImg" *ngIf="checkUserMemberDetails(updateProfileForm.value.additionalMembers, i ,'imgIdProofMember')">
                                            <img src="assets/images/delete.png" (click)="deleteImage(i, 'imgIdProofMember')">
                                        </div>
                                    </div>
                                    <!-- <button class="btn btn-green">Upload Proof of ID</button> -->
                                    <div>
                                        <label [for]="i+'id'" class="btn btn-green">
                                            Upload Proof of ID
                                        </label>
                                        <input [id]="i+'id'" (change)="uploadImages($event, i, 'imgIdProofMember')" type="file" accept="image/*" />
                                    </div>
                                </div>
                                <div class="col-sm-6 wh_input">
                                    <!-- <button class="btn btn-green">Upload Proof of income</button> -->
                                    <div class="alignImage">
                                        <div class="familyDoc">
                                            <img [src]="checkUserMemberDetails(updateProfileForm.value.additionalMembers, i, 'imgIncomeProofMember')?checkUserMemberDetails(updateProfileForm.value.additionalMembers, i, 'imgIncomeProofMember'): defaultImage" (click) = "openImage(template1 ,checkUserMemberDetails(updateProfileForm.value.additionalMembers, i, 'imgIncomeProofMember'))">
                                        </div>
                                        <div class="delteImg" *ngIf="checkUserMemberDetails(updateProfileForm.value.additionalMembers, i ,'imgIncomeProofMember')">
                                            <img src="assets/images/delete.png"  (click)="deleteImage(i, 'imgIncomeProofMember')">
                                        </div>
                                    </div>
                                    <div>
                                        <label [for]="i+'income'" class="btn btn-green">
                                            Upload Proof of income
                                        </label>
                                        <input [id]="i+'income'" (change)="uploadImages($event, i, 'imgIncomeProofMember')" type="file" accept="image/*" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <!-- ------ -->
                    <div class="spacer"></div>
                    <div class="col-sm-6 wh_input">
                        <label>Bedroom Size Required <span class="danger">*</span></label>
                        <ng-select [searchable]=false   class="form-control" [items]="bedroomSize" formControlName="bedroomSize" placeholder="Select Bedsize"> </ng-select>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.bedroomSize.dirty || updateProfileForm.controls.bedroomSize.touched) && updateProfileForm.controls.bedroomSize.errors">
                            <small *ngIf="updateProfileForm.controls.bedroomSize.errors.required">Bedroom size is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Current Accommodation <span class="danger">*</span></label>
                        <ng-select [searchable]=false  class="form-control" [items]="currentAccommodation" formControlName="currentAccommodation" placeholder="Select current accomodation"> </ng-select>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.currentAccommodation.dirty || updateProfileForm.controls.currentAccommodation.touched) && updateProfileForm.controls.currentAccommodation.errors">
                            <small *ngIf="updateProfileForm.controls.currentAccommodation.errors.required">Current Accomodation is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Current Tenancy Type <span class="danger">*</span></label>
                        <ng-select [searchable]=false  class="form-control" [items]="currentTenancyType" formControlName="currentTenancyType" placeholder="Select current tenancy type"> </ng-select>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.currentTenancyType.dirty || updateProfileForm.controls.currentTenancyType.touched) && updateProfileForm.controls.currentTenancyType.errors">
                            <small *ngIf="updateProfileForm.controls.currentTenancyType.errors.required">Current Tenancy Type is required</small>
                        </div>
                    </div>
                    <!-- <div class="col-sm-4 wh_radio_btn">
                        <label>Do you have rent arrears?</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isRentArrearsProvided" type="radio" id="customRadio1" class="custom-control-input" [value]="true" formControlName="isRentArrearsProvided">
                            <label for="customRadio2" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isRentArrearsProvided" type="radio" id="customRadio2" class="custom-control-input" [value]="false" formControlName="isRentArrearsProvided">
                            <label for="customRadio1" class="custom-control-label">No</label>
                        </div>
                    </div> -->
                    <div class="spacer"></div>
                    <div class="col-sm-4 wh_radio_btn">
                        <label>Do you have rent arrears?</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input type="radio" id="rentArrear1" class="custom-control-input" [value]="true" formControlName="isRentArrearsProvided">
                            <label for="rentArrear1" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input type="radio" id="rentArrear2" class="custom-control-input" [value]="false" formControlName="isRentArrearsProvided">
                            <label for="rentArrear2" class="custom-control-label">No</label>
                        </div>
                    </div>
                    <div class="col-sm-4 wh_radio_btn">
                        <label>Medical</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input type="radio" id="medical1" class="custom-control-input" [value]="true" formControlName="isMedicalDetailsProvided">
                            <label for="medical1" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input type="radio" id="medical2" class="custom-control-input" [value]="false" formControlName="isMedicalDetailsProvided">
                            <label for="medical2" class="custom-control-label">No</label>
                        </div>
                    </div>
                    <div class="col-sm-4 wh_radio_btn">
                        <label>ASB</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input type="radio" id="ABS1" class="custom-control-input" [value]="true" formControlName="isASBDetailsProvided">
                            <label for="ABS1" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input type="radio" id="ABS2" class="custom-control-input" [value]="false" formControlName="isASBDetailsProvided">
                            <label for="ABS2" class="custom-control-label">No</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div *ngIf="updateProfileForm.controls.isRentArrearsProvided.value" class="col-sm-6 wh_input">
                                <label>Rent Arrear Amount</label>
                                <!-- <input type="text" class="form-control" placeholder="Rent arrear amount" formControlName="rentArrearsAmount"> -->
                                <textarea class="form-control" formControlName="rentArrearsAmount" placeholder="Rent arrear amount"></textarea>
                                <div class="has-error" *ngIf="(updateProfileForm.controls.rentArrearsAmount.dirty || updateProfileForm.controls.rentArrearsAmount.touched) && updateProfileForm.controls.rentArrearsAmount.errors">
                                    <small *ngIf="updateProfileForm.controls.rentArrearsAmount.errors.pattern">Enter valid Amount</small>
                                </div>
                            </div>

                            <div *ngIf="updateProfileForm.controls.isMedicalDetailsProvided.value" class="col-sm-12 wh_input">
                                <label>Medical Details</label>
                                <!-- <input type="text" class="form-control" placeholder="Medical Details" formControlName="medicalDetails"> -->
                                <textarea  class="form-control" formControlName="medicalDetails" placeholder="Medical Details"></textarea>
                            </div>

                            <div *ngIf="updateProfileForm.controls.isASBDetailsProvided.value" class="col-sm-12 wh_input">
                                <label>ASB Details</label>
                                <!-- <input type="text" class="form-control" placeholder="ASB details" formControlName="asbDetails"> -->
                                <textarea  class="form-control" formControlName="asbDetails" placeholder="ASB details"></textarea>
                            </div>

                        </div>
                    </div>

                    <!-- <div class="col-sm-6 wh_input">
                        <label>Email Address 1 <span class="danger">*</span></label>
                        <input type="email" class="form-control" placeholder="Email 1" formControlName="email1">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.email1.dirty || updateProfileForm.controls.email1.touched) && updateProfileForm.controls.email1.errors">
                            <small *ngIf="updateProfileForm.controls.email1.errors.required">Email is required</small>
                            <small *ngIf="updateProfileForm.controls.email1.errors.pattern">Enter valid Email</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Email Address 2</label>
                        <input type="email" class="form-control" placeholder="Email 2" formControlName="email2">
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Phone Number <span class="danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Phone number" formControlName="phoneNumber1">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.phoneNumber1.dirty || updateProfileForm.controls.phoneNumber1.touched) && updateProfileForm.controls.phoneNumber1.errors">
                            <small *ngIf="updateProfileForm.controls.phoneNumber1.errors.required">Phone number is required</small>
                            <small *ngIf="updateProfileForm.controls.phoneNumber1.errors.pattern">Enter valid Phone Number</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Phone Number 2</label>
                        <input type="text" class="form-control" placeholder="Phone number 2" formControlName="phoneNumber2">
                    </div> -->
                    <!--new section-->
                    <!-- <div class="spacer"></div>
                    <h3>Address History 1</h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory1" placeholder="Address History 1"></textarea>
                    </div>
                    <div class="spacer"></div>
                    <h3>Address History 2</h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory2" placeholder="Address History 2"></textarea>
                    </div>
                    <div class="spacer"></div>
                    <h3>Address History 3</h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory3" placeholder="Address History 3"></textarea>
                    </div>
                    <div class="spacer"></div>
                    <h3>Address History 3</h3>
                    <div class="col-sm-12 wh_input">
                        <textarea  class="form-control" formControlName="addressHistory4" placeholder="Address History 4"></textarea>
                    </div> -->
                    <div class="spacer"></div>
                    <h3>Employment Details (if applicable)</h3>
                    <div class="col-sm-12 wh_input">
                        <label>Name and address of employer together with hours worked per week</label>
                        <textarea  class="form-control" formControlName="employmentDetails"></textarea>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Income <span class="danger">*</span></label>
                        <input type="text" class="form-control" formControlName="income">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.income.dirty || updateProfileForm.controls.income.touched) && updateProfileForm.controls.income.errors">
                            <small *ngIf="updateProfileForm.controls.income.errors.required">Income is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>National Insurance Number <span class="danger">*</span></label>
                        <input type="text" class="form-control" formControlName="nationalInsuranceNumber">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.nationalInsuranceNumber.dirty || updateProfileForm.controls.nationalInsuranceNumber.touched) && updateProfileForm.controls.nationalInsuranceNumber.errors">
                            <small *ngIf="updateProfileForm.controls.nationalInsuranceNumber.errors.required">National Insurance Number is required</small>
                        </div>
                    </div>

                    <div class="spacer"></div>
                    <div class="col-sm-12 wh_input">
                        <label>Is the applicant a danger to staff or the public (Please state risk low, medium or high)
                            <span class="danger">*</span></label>
                        <textarea class="form-control" formControlName="applicantDangerToStaff"></textarea>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.applicantDangerToStaff.dirty || updateProfileForm.controls.applicantDangerToStaff.touched) && updateProfileForm.controls.applicantDangerToStaff.errors">
                            <small *ngIf="updateProfileForm.controls.applicantDangerToStaff.errors.required">Applicant Danger To Staff is required</small>
                        </div>
                        
                    </div>

                    <div class="spacer"></div>                           
                        <div class="col-sm-12 wh_input">
                            <label>Accommodation Required <span class="danger">*</span> </label>
                            <p>Please state the address of the property you are applying for and how many bedrooms you need.</p>
                            <input type="text" formControlName="accommodationRequired" class="form-control">
                            <div class="has-error" *ngIf="(updateProfileForm.controls.accommodationRequired.dirty || updateProfileForm.controls.accommodationRequired.touched) && updateProfileForm.controls.accommodationRequired.errors">
                                <small *ngIf="updateProfileForm.controls.accommodationRequired.errors.required">Accommodation is required</small>
                            </div>
                        </div>

                    <!--new section ends-->
                    <div class="spacer"></div>
                    <h3 class="mb-2">My Documents</h3>
                    <span class="tag_line">You can upload multiple proof of ID, proof of income and bank statement</span>
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-6 document_box">
                                <div class="document_box_inner">
                                    <h5>ID</h5>
                                    <div class="upload_btn">
                                        <!-- <button class="btn btn-bordered">Upload Proof of ID</button> -->
                                        <label for="proof-upload" class="btn btn-bordered">
                                            Upload Proof of ID
                                        </label>
                                        <input id="proof-upload" (change)="uploadUserDocuments($event,0,'arrImgIdProof')" type="file" accept="image/*, application/pdf , application/zip , text/plain , .xlsx, .xls, .csv , .doc, .docx" />
                                        <a class="info-button" target="_blank" href="https://www.mysocialhousing.co.uk/app-proof-of-id">
                                            <img src="assets/images/information.png" >
                                            <span>Find out what we can accept as proof of id</span>
                                        </a>
                                    </div>
                                    <!-- <div *ngIf="updateProfileForm.controls.arrImgIdProof.length > 0"> -->
                                    <div *ngIf="id_Proof_Ary.length > 0">
                                        <div class="upload_imgsec" *ngFor="let item of id_Proof_Ary; let i = index;">
                                            <div class="upload_img_loop">
                                                <div class="img-up img-con">
                                                    <img [src]="item" (click) = "openImage(template1 ,item)">
                                                    <div class="del-Icon" >
                                                        <span (click)="deleteUserDocuments(i,'arrImgIdProof')">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 document_box">
                                <div class="document_box_inner">
                                    <h5>Income</h5>
                                    <div class="upload_btn">
                                        <!-- <button class="btn btn-bordered">Upload Proof of Income</button> -->
                                        <label for="income-upload" class="btn btn-bordered">
                                            Upload Proof of Income
                                        </label>
                                        <input id="income-upload" (change)="uploadUserDocuments($event,0,'arrImgIncomeProof')" type="file" accept="image/*, application/pdf , application/zip , text/plain , .xlsx, .xls, .csv , .doc, .docx" />
                                        <a class="info-button" target="_blank" href="https://www.mysocialhousing.co.uk/app-proof-of-income">
                                            <img src="assets/images/information.png" >
                                            <span>Find out what we can accept as proof of income</span>
                                        </a>
                                    </div>
                                    <div *ngIf="inc_Proof_Ary.length > 0">
                                        <div class="upload_imgsec" *ngFor="let item of inc_Proof_Ary; let i = index;">
                                            <div class="upload_img_loop">
                                                <div class="img-up img-con">
                                                    <img [src]="item" (click) = "openImage(template1 ,item)">
                                                    <div class="del-Icon" >
                                                        <span (click)="deleteUserDocuments(i,'arrImgIncomeProof')">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 document_box">
                                <div class="document_box_inner">
                                    <h5>Statements</h5>
                                    <div class="upload_btn">
                                        <!-- <button class="btn btn-bordered">Upload Proof of Statements</button> -->
                                        <label for="statement-upload" class="btn btn-bordered">
                                            Upload Proof of Statements
                                        </label>
                                        <input id="statement-upload" (change)="uploadUserDocuments($event,0,'arrImgBankStatements')" type="file" accept="image/*, application/pdf , application/zip , text/plain , .xlsx, .xls, .csv , .doc, .docx" />
                                    </div>
                                    <div *ngIf="bank_Proof_Ary.length > 0">
                                        <div class="upload_imgsec" *ngFor="let item of bank_Proof_Ary; let i = index;">
                                            <div class="upload_img_loop">
                                                <div class="img-up img-con">
                                                    <img [src]="item" (click) = "openImage(template1 ,item)">
                                                    <div class="del-Icon">
                                                        <span (click)="deleteUserDocuments(i,'arrImgBankStatements')">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 document_box" *ngIf="updateProfileForm.controls.isMedicalDetailsProvided.value">
                                <div class="document_box_inner">   
                                    <h5>Medical</h5>                                 
                                    <div class="upload_btn">
                                        <label for="medical-upload" class="btn btn-bordered">
                                            Upload Proof of Medical
                                        </label>
                                        <input id="medical-upload" (change)="uploadUserDocuments($event,0,'arrImgMedicalProof')" type="file" accept="image/*" />
                                    </div>
                                    <div *ngIf="medical_Proof_Ary.length > 0">
                                        <div class="upload_imgsec" *ngFor="let item of medical_Proof_Ary; let i = index;">
                                            <div class="upload_img_loop">
                                                <div class="img-up img-con">
                                                    <img [src]="item" (click) = "openImage(template1 ,item)">
                                                    <div class="del-Icon">
                                                        <span (click)="deleteUserDocuments(i,'arrImgMedicalProof')">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    
                    <div class="spacer"></div>
                    <h3>Referring Agency</h3>
                        <div class="col-sm-6 wh_input">
                            <label>First Name <span class="danger">*</span></label>
                            <input type="text" class="form-control" formControlName="firstNameRA" placeholder="First name">
                            <div class="has-error" *ngIf="(updateProfileForm.controls.firstNameRA.dirty || updateProfileForm.controls.firstNameRA.touched) && updateProfileForm.controls.firstNameRA.errors">
                                <small *ngIf="updateProfileForm.controls.firstNameRA.errors.required">First name is required</small>
                            </div>
                        </div>
                        <div class="col-sm-6 wh_input">
                            <label>Last Name</label>
                            <input type="text" class="form-control" formControlName="lastNameRA" placeholder="Last name">
                            <div class="has-error" *ngIf="(updateProfileForm.controls.lastNameRA.dirty || updateProfileForm.controls.lastNameRA.touched) && updateProfileForm.controls.lastNameRA.errors">
                                <small *ngIf="updateProfileForm.controls.lastNameRA.errors.required">Last name is required</small>
                            </div>
                        </div>
                        <div class="col-sm-6 wh_input">
                            <label>Email <span class="danger">*</span></label>
                            <input  type="email" formControlName="emailRA" class="form-control" placeholder="email">
                            <div class="has-error" *ngIf="(updateProfileForm.controls.emailRA.dirty || updateProfileForm.controls.emailRA.touched) && updateProfileForm.controls.emailRA.errors">
                                <small *ngIf="updateProfileForm.controls.emailRA.errors.required">Email is required</small>
                            </div>
                        </div>
                        <div class="col-sm-6 wh_input">
                            <label>Contact Number <span class="danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Contact number" formControlName="phoneNumberRA">
                            <div class="has-error" *ngIf="(updateProfileForm.controls.phoneNumberRA.dirty || updateProfileForm.controls.phoneNumberRA.touched) && updateProfileForm.controls.phoneNumberRA.errors">
                                <small *ngIf="updateProfileForm.controls.phoneNumberRA.errors.required">Phone number is required</small>
                                <small *ngIf="updateProfileForm.controls.phoneNumberRA.errors.pattern">Enter valid Phone Number</small>
                            </div>
                        </div>
                        <div class="col-sm-12 wh_input">
                            <label><span class="danger">*</span></label>
                            <label><input type="checkbox"  formControlName="agreeRA"> I agree that all of the information above is correct to the best of your knowledge. </label>
                            <div class="has-error" *ngIf="(updateProfileForm.controls.agreeRA.dirty || updateProfileForm.controls.agreeRA.touched) && updateProfileForm.controls.agreeRA.errors">
                                <small *ngIf="updateProfileForm.controls.agreeRA.errors.required">Accept the Referring Agency information is correct.</small>
                            </div>
                        </div>
                        <div class="col-sm-12 wh_input">
                            <label>Application Terms and Conditions<span class="danger">*</span></label><br/>
                            <label><input type="checkbox" formControlName="appTermAndCondition"> I have read, understood, and accepted the <a href="https://mysocialhousing.co.uk/terms-of-use/" target="_blank">Application Terms and Conditions.</a> </label>
                            <div class="has-error" *ngIf="(updateProfileForm.controls.appTermAndCondition.dirty || updateProfileForm.controls.appTermAndCondition.touched) && updateProfileForm.controls.appTermAndCondition.errors">
                                <small *ngIf="updateProfileForm.controls.appTermAndCondition.errors.required">Accept the Application Terms and Conditions.</small>
                            </div>
                        </div>
                        <div class="col-sm-12 wh_input">
                            <label>Privacy Policy<span class="danger">*</span></label><br/>
                            <label><input type="checkbox"  formControlName="privacyPolicy"> I have read, understood, and accepted the <a href="https://mysocialhousing.co.uk/privacy-policy/"  target="_blank">Privacy Policy </a> for  an application referral. </label>
                            <div class="has-error" *ngIf="(updateProfileForm.controls.privacyPolicy.dirty || updateProfileForm.controls.privacyPolicy.touched) && updateProfileForm.controls.privacyPolicy.errors">
                                <small *ngIf="updateProfileForm.controls.privacyPolicy.errors.required">Accept the Privacy Policy.</small>
                            </div>
                        </div>

                    <div class="col-sm-12 text-center save_btns">
                        <a class="read-right"  (click)="openFile()" >
                            <span>Read the Right to Rent Guide</span>
                        </a>
                        <button type="submit" class="btn btn-green ">Save Profile</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<ng-template #template1>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="dialog_img_inner">
            <img *ngIf="viewImage" [src]="viewImage" style="min-width: 500px;">
            <img *ngIf="!viewImage" src="assets/images/bg1.png" style="min-width: 500px;"s>
        </div>
    </div>

</ng-template>

<div id="form_login" class="password-section" *ngIf="publicformVisibility == false">
    <div>
        <form [formGroup]="protectedPass" (ngSubmit)="onPasswordSubmit()">
            <h2 class="utility-title">Protected Page</h2>
            <input type="password"  autofocus="true" maxlength="256" name="pass" placeholder="Enter your password" formControlName="password" class="form-control public-form-password">
            <div style="margin-top: 16px">
                <button class="btn btn-primary form-control" type="submit" value="Submit">Submit</button>
            </div>
        </form>
    </div>
</div>
