import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseQueryService } from 'src/app/core/services/firebase-query';
import { AlertNotification } from 'src/app/core/alertNotification/alertNotification';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import * as _ from "lodash"
import { UserDataService } from 'src/app/core/services/user-data.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ApiServiceService } from 'src/app/core/services/api-service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import  { MustMatch, PostalCodeValidator } from 'src/app/shared/helpers/postcode-match';

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  $userDataSubscription: Subscription;
  relationshipOption = [
    "wife",
    "Husband",
    "Partner",
    "Daughter",
    "Son",
    "Friend",
    "Grandmother",
    "Grandfather",
    "Brother",
    "Sister",
    "other",
  ];
  bedroomSize = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  currentAccommodation = [
    "Housing Association",
    "Council",
    "Private Landlord",
    "Hostel",
    "B&B",
    "NFA",
    "Temporary Accomodation",
    "Stayin with family/friends",
    "Other",
  ];
  showFamilyForms: boolean = true;
  updateProfileForm: any;
  additionalMembers: FormArray;
  showFamilyDetails: boolean = false;
  userDetails = null;
  id_Proof_Ary = [];
  inc_Proof_Ary = [];
  bank_Proof_Ary = [];
  referenceAgents = [];
  userData: any;
  defaultImage = "assets/images/photo.png";
  modalRef: BsModalRef;
  viewImage: any;
  changeNumBtn = 'Change number?';
  buttonDisabled = false;
  currentDate = new Date();
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private firebaseservice: FirebaseQueryService,
    private alertnotification: AlertNotification,
    private loaderService: NgxSpinnerService,
    private userDataService: UserDataService,
    private apiservice: ApiServiceService,
    private modalService: BsModalService
  ) {
    if (localStorage.getItem("userDetail")) {
      this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
      this.updateProfileForm = this.formBuilder.group({
        imgProfile: [""],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        email: [""],
        address: ["", [Validators.required]],
        postCode: ["", [Validators.required, PostalCodeValidator]],
        dateOfBirth: ["", [Validators.required]],
        referringAgent: ["", [Validators.required]],
        landlordName: ["", [Validators.required]],
        landlordPhoneNumber: [
          "",
          [Validators.required, Validators.pattern(/^[0-9]\d*$/)],
        ],
        landlordEmail: [""],
        firstNameFC: [""],
        lastNameFC: [""],
        dateOfBirthFC: [""],
        relationshipToYouFC: [""],
        bedroomSize: ["", [Validators.required]],
        currentAccommodation: ["", [Validators.required]],
        isRentArrearsProvided: [false],
        isMedicalDetailsProvided: [false],
        isASBDetailsProvided: [false],
        email1: ["", [Validators.required, Validators.email]],
        email2: [""],
        phoneNumber1: [
          "",
          [Validators.required, Validators.pattern(/^[0-9]\d*$/)],
        ],
        phoneNumber2: [""],
        medicalDetails: [""],
        asbDetails: [""],
        rentArrearsAmount: [0, [Validators.pattern(/^[0-9]\d*$/)]],
        additionalMembers: this.formBuilder.array([]),
        arrImgIdProof: this.formBuilder.array([]),
        arrImgIncomeProof: this.formBuilder.array([]),
        arrImgBankStatements: this.formBuilder.array([]),
      });
      this.setUserDetailsForForm();
    } else {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }
  }

  fetchUserDetails() {
    this.$userDataSubscription = this.userDataService.currentuser.subscribe(
      (data) => {
        this.userData = data;
        if (this.userData && localStorage.getItem("userDetail")) {
          this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
          const additionalMembers = this.updateProfileForm.get(
            "additionalMembers"
          ) as FormArray;
          additionalMembers.clear();
          this.id_Proof_Ary = [];
          this.inc_Proof_Ary = [];
          this.bank_Proof_Ary = [];
          this.setUserDetailsForForm();
        }
      }
    );
  }

  ngOnInit(): void {
    console.log(this.userDetails);
    if (this.userDetails.isPhoneVerified === false) {
      // this.verifyPhoneNumber();
      let otp = Math.floor(Math.random() * 899999 + 100000);
      let data = {phoneNumber1: this.userDetails.phoneNumber1 , verificationCode:otp};
      this.sendOTP(data);
      document.getElementById("openModal").click();
    }
    this.fetchUserDetails();
    this.fetchReferencingAgents();
  }
  verifyPhoneNumber() {
    Swal.fire({
      title: "Verify your phone number",
      html: `${this.userDetails.phoneNumber1} <a href="" onclick='changePhoneNumber()'> Change phone number?</a>`,
      input: "number",
      inputValue: null,
      showCancelButton: true,
      confirmButtonText: "verify",
      inputValidator: (value) => {
        if (!value) {
          return "please enter vaild code!";
        }
      },
    } as any).then((result) => {
      console.log("Result:" + result.value);
    });
  }
  changePhoneNumber() {
    Swal.fire({
      title: "Change your phone number",
      text: this.userDetails.phoneNumber1,
      input: "number",
      inputValue: null,
      showCancelButton: true,
      confirmButtonText: "verify",
      inputValidator: (value) => {
        if (!value) {
          return "please enter vaild code!";
        }
      },
    } as any).then((result) => {
      console.log("Result:" + result.value);
    });
  }

  fetchReferencingAgents() {
    this.apiservice.getRequest(`/getRefferingAgent.php`, {}, false).subscribe(
      (response: any) => {
        this.referenceAgents = response.data;
      },
      (error) => {
        // console.log(error.error);
        this.alertnotification.errorAlert(error.error.message);
      }
    );
  }

  setUserDetailsForForm() {
    if (
      this.userDetails &&
      this.userDetails.arrImgIdProof &&
      this.userDetails.arrImgIdProof.length > 0
    ) {
      this.userDetails.arrImgIdProof.map((x) => {
        // const arrImgIdProof = this.updateProfileForm.get('arrImgIdProof') as FormArray;
        // arrImgIdProof.push(this.add(x));
        this.id_Proof_Ary.push(x);
      });
    }
    if (
      this.userDetails &&
      this.userDetails.arrImgIncomeProof &&
      this.userDetails.arrImgIncomeProof.length > 0
    ) {
      this.userDetails.arrImgIncomeProof.map((x) => {
        // const arrImgIncomeProof = this.updateProfileForm.get('arrImgIncomeProof') as FormArray;
        // arrImgIncomeProof.push(this.add(x));
        this.inc_Proof_Ary.push(x);
      });
    }
    if (
      this.userDetails &&
      this.userDetails.arrImgBankStatements &&
      this.userDetails.arrImgBankStatements.length > 0
    ) {
      // const arrImgBankStatements = this.updateProfileForm.get('arrImgBankStatements') as FormArray;
      this.userDetails.arrImgBankStatements.map((x) => {
        // arrImgBankStatements.push(this.add(x));
        this.bank_Proof_Ary.push(x);
      });
    }

    if (
      this.userDetails &&
      this.userDetails.additionalMembers &&
      this.userDetails.additionalMembers.length > 0
    ) {
      const additionalMembers = this.updateProfileForm.get(
        "additionalMembers"
      ) as FormArray;
      this.userDetails.additionalMembers.map((x) => {
        additionalMembers.push(this.createItem(x));
      });
      if (this.userDetails.additionalMembers.length < 5) {
        for (
          let idx = this.userDetails.additionalMembers.length - 1;
          idx <= 4;
          idx++
        ) {
          additionalMembers.push(this.createItem(null));
        }
      }
    } else {
      const additionalMembers = this.updateProfileForm.get(
        "additionalMembers"
      ) as FormArray;
      this.userDetails.additionalMembers = [];
      for (let index = 0; index <= 4; index++) {
        additionalMembers.push(this.createItem(null));
        this.userDetails.additionalMembers.push(this.createDefaultMembers());
      }
    }

    if (this.userDetails && this.userDetails.imgProfile) {
      this.updateProfileForm.patchValue({
        imgProfile: this.userDetails.imgProfile,
      });
    }
  }

  add(x): FormGroup {
    return this.formBuilder.group(x);
  }

  createItem(data): FormGroup {
    return this.formBuilder.group({
      firstName: [data && data.firstName ? data.firstName : ""],
      lastName: [data && data.lastName ? data.lastName : ""],
      dateOfBirth: [
        data && data.dateOfBirth ? moment(data.dateOfBirth).toDate() : "",
      ],
      relationshipToYou: [
        data && data.relationshipToYou ? data.relationshipToYou : "",
      ],
      imgIdProofMember: [
        data && data.imgIdProofMember ? data.imgIdProofMember : "",
      ],
      imgIncomeProofMember: [
        data && data.imgIncomeProofMember ? data.imgIncomeProofMember : "",
      ],
    });
  }

  createDefaultMembers() {
    return {
      dateOfBirth: "",
      firstName: "",
      imgIdProofMember: "",
      imgIncomeProofMember: "",
      lastName: "",
      relationshipToYou: "",
    };
  }

  addItem(): void {
    this.additionalMembers = this.updateProfileForm.get(
      "additionalMembers"
    ) as FormArray;
    this.additionalMembers.push(this.createItem(null));
    console.log("add clicked");
  }

  showAddFamilyForms() {
    this.showFamilyForms = !this.showFamilyForms;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.updateProfileForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
    return invalid;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onSubmit(checkValidation) {
    Object.keys(this.updateProfileForm.value).forEach((key) =>
      this.updateProfileForm.value[key] === undefined
        ? delete this.updateProfileForm.value[key]
        : {}
    );

    this.findInvalidControls();
    this.markFormGroupTouched(this.updateProfileForm);

    if (this.updateProfileForm.invalid && checkValidation) {
      this.alertnotification.errorAlert(
        "Please complete required profile information"
      );
      return;
    } else {
      this.updateProfileForm.value["arrImgIdProof"] = [];
      this.updateProfileForm.value["arrImgIncomeProof"] = [];
      this.updateProfileForm.value["arrImgBankStatements"] = [];
      if (this.id_Proof_Ary && this.id_Proof_Ary.length > 0) {
        this.id_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgIdProof"].push(x);
        });
      }
      if (this.inc_Proof_Ary && this.inc_Proof_Ary.length > 0) {
        this.inc_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgIncomeProof"].push(x);
        });
      }
      if (this.bank_Proof_Ary && this.bank_Proof_Ary.length > 0) {
        this.bank_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgBankStatements"].push(x);
        });
      }

      if (
        !this.inc_Proof_Ary.length ||
        !this.bank_Proof_Ary.length ||
        !this.id_Proof_Ary.length
      ) {
        this.alertnotification.errorAlert(
          "Please upload your proof of ID, proof of income and bank statement"
        );
        return;
      }

      if (
        typeof this.userDetails.status != "undefined" &&
        this.userDetails.status == "suspended"
      ) {
        this.updateProfileForm.value["suspended"] = false;
        this.updateProfileForm.value["status"] = "";
      }

      this.updateProfileForm.value["isProfileSaved"] = true;

      console.log(this.updateProfileForm.value);

      _.map(
        this.updateProfileForm.value.additionalMembers,
        (additionalMember) => {
          if (additionalMember.dateOfBirth) {
            additionalMember.dateOfBirth = moment(
              additionalMember.dateOfBirth,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY");
          }
        }
      );

      this.firebaseservice
        .updateUserRecords(this.userDetails.id, this.updateProfileForm.value)
        .then(async (records) => {
          // this.loaderService.hide();
          console.log("successfully updated");
          this.alertnotification.successAlert("successfully updated");
          try {
            const userDetail = await this.getUserDetails(
              this.userDetails.userId
            );
            console.log(userDetail);
            this.loaderService.hide();
            this.userDataService.changeuser(userDetail);
          } catch {
            this.loaderService.hide();
          }
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
        });
    }
  }

  removeItem(i) {
    this.updateProfileForm.get("additionalMembers").removeAt(i);
  }

  async uploadImages(event, i, fieldname) {
    const blob = event.target.files[0];
    var childName;
    if (fieldname == "imgProfile") {
      childName = `images/profile_${new Date().getMilliseconds()}_${
        this.userDetails.userId
      }`;
      let ImageUrl = await this.commonUploadFile(blob, childName);
      this.updateProfileForm.value["imgProfile"] = ImageUrl;
      this.userDetails.imgProfile = ImageUrl;
      // this.onSubmit(false);
    } else if (fieldname == "imgIdProofMember") {
      console.log(this.userDetails);
      debugger;
      childName = `images/id_member_${i + 2}_${this.userDetails.userId}`;
      let ImageUrl = await this.commonUploadFile(blob, childName);
      this.updateProfileForm.value.additionalMembers[i][fieldname] = ImageUrl;
      this.userDetails.additionalMembers[i][fieldname] = ImageUrl;
      // this.onSubmit(false);
    } else {
      childName = `images/income_member_${i + 2}_${this.userDetails.userId}`;
      let ImageUrl = await this.commonUploadFile(blob, childName);
      this.updateProfileForm.value.additionalMembers[i][fieldname] = ImageUrl;
      this.userDetails.additionalMembers[i][fieldname] = ImageUrl;
      // this.onSubmit(false);
    }
  }

  async uploadUserDocuments(event, i, fieldname) {
    // console.log(fieldname);
    var childName;
    if (event.target.files.length > 0) {
      for (var x = 0; x < event.target.files.length; x++) {
        const blob = event.target.files[x];

        if (fieldname == "arrImgIdProof") {
          childName = `images/id_${
            this.id_Proof_Ary.length + 1
          }_${new Date().getMilliseconds()}_${
            this.userDetails.userId
          }.${blob.name.split(".").pop()}`;
          let ImageUrl = await this.commonUploadFile(blob, childName);
          this.id_Proof_Ary.push(ImageUrl);
          this.onSubmit(false);
        } else if (fieldname == "arrImgIncomeProof") {
          childName = `images/income_${
            this.inc_Proof_Ary.length + 1
          }_${new Date().getMilliseconds()}_${
            this.userDetails.userId
          }.${blob.name.split(".").pop()}`;
          let ImageUrl = await this.commonUploadFile(blob, childName);
          this.inc_Proof_Ary.push(ImageUrl);
          this.onSubmit(false);
        } else {
          childName = `images/bank_${
            this.bank_Proof_Ary.length + 1
          }_${new Date().getMilliseconds()}_${
            this.userDetails.userId
          }.${blob.name.split(".").pop()}`;
          let ImageUrl = await this.commonUploadFile(blob, childName);
          this.bank_Proof_Ary.push(ImageUrl);
          this.onSubmit(false);
        }
      }
    } else {
      return;
    }
  }

  commonUploadFile(blob, childName) {
    return new Promise((resolve, reject) => {
      this.firebaseservice
        .uploadFile(blob, childName)
        .then((snapshot) => {
          snapshot.ref
            .getDownloadURL()
            .then((url) => {
              // console.log(url);
              this.loaderService.hide();
              resolve(url);
            })
            .catch((error) => {
              this.loaderService.hide();
              console.log(error);
              reject(false);
              // return false;
            });
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
          reject(false);
        });
    });
  }

  getUserDetails(id) {
    var records1 = [];
    return new Promise((resolve, reject) => {
      this.firebaseservice
        .getUserDetail(id)
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            var data = doc.data();
            data["id"] = doc.id;
            records1.push(data);
          });
          localStorage.setItem("userDetail", JSON.stringify(records1[0]));
          this.userDataService.changeuser(records1[0]);
          resolve(true);
          // this.loaderService.hide();
        })
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
            reject(false);
          }.bind(this)
        );
    });
  }

  dateConverter(date) {
    // console.log(date);
    return date && date.seconds ? moment.unix(date.seconds).toDate() : "";
  }

  check(eee) {
    console.log(eee);
  }

  checkUserMemberDetails(data, i, field) {
    if (field == "imgIncomeProofMember" || field == "imgIdProofMember") {
      return data && data.length > 0 ? data[i][field] : "";
    } else {
      return data && data.length > 0 ? data[i][field] : "";
    }
  }

  ngOnDestroy() {
    if (this.$userDataSubscription) {
      this.$userDataSubscription.unsubscribe();
    }
  }

  deleteImage(idx, fieldname) {
    Swal.fire({
      title: `Do you want to delete this ${
        fieldname == "imgIdProofMember" ? "Proof of ID" : "Proof of income"
      }?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value == true) {
        this.updateProfileForm.value.additionalMembers[idx][fieldname] = "";
        this.userDetails.additionalMembers[idx][fieldname] = "";
        // this.onSubmit(false);
      } else {
        return;
      }
    });
  }

  deleteUserDocuments(idx, fieldname) {
    Swal.fire({
      title: `Do you want to delete this ${
        fieldname == "arrImgIdProof"
          ? "Proof of ID"
          : fieldname == "arrImgIncomeProof"
          ? "Proof of income"
          : "Proof of Statements"
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value == true) {
        if (fieldname == "arrImgIdProof") {
          this.id_Proof_Ary.splice(idx, 1);
          this.onSubmit(false);
        } else if (fieldname == "arrImgIncomeProof") {
          this.inc_Proof_Ary.splice(idx, 1);
          this.onSubmit(false);
        } else {
          this.bank_Proof_Ary.splice(idx, 1);
          this.onSubmit(false);
        }
      } else {
        return;
      }
    });
  }

  openModal(template2: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template2, {
      class: "modal-show-open",
      backdrop : 'static',
      keyboard : false
    });
  }
  selectDiv = false;

  toggleDiv() {
    this.selectDiv = !this.selectDiv;
    console.log("div false:",this.selectDiv);
    if(this.selectDiv === true){
      this.changeNumBtn = 'Update Number';
      this.buttonDisabled = true;
    }else{
      this.changeNumBtn = 'Change number?';
      this.buttonDisabled = false;
      let num = (document.getElementById("changeNum") as HTMLInputElement).value
      let otp = Math.floor(Math.random() * 899999 + 100000);
      this.loaderService.show()
      let data = {phoneNumber1: num , verificationCode:otp};
      this.sendOTP(data);
    }
  }

  sendOTP(data){
    this.userDetails.verificationCode = data.verificationCode;
    this.firebaseservice
      .updateUserRecords(this.userDetails.id, data)
      .then(async (records) => {
        console.log("successfully updated");
        this.alertnotification.successAlert("Verification code sent to your phone");
        try {
          const userDetail = await this.getUserDetails(
            this.userDetails.userId
          );
          console.log(userDetail);
          this.apiservice.postRequestPush(`genrateOTP`, data).subscribe((response: any) => {
            console.log(response);
          },
            error => {
              // console.log(error.error);
              this.alertnotification.errorAlert(error.error.message);
            });
          this.loaderService.hide();
          this.userDataService.changeuser(userDetail);
        } catch {
          this.loaderService.hide();
        }
      })
      .catch((error) => {
        this.loaderService.hide();
        console.log(error);
      });
  }

  verify(){
    this.loaderService.show();
    let verifyCode = (document.getElementById("verifyCode") as HTMLInputElement).value
    if(this.userDetails.verificationCode == Number(verifyCode)){
      this.firebaseservice
      .updateUserRecords(this.userDetails.id, {isPhoneVerified:true})
      .then(async (records) => {
        console.log("successfully updated");
        this.alertnotification.successAlert("Phone verification code is accepted");
        try {
          const userDetail = await this.getUserDetails(
            this.userDetails.userId
          );
          console.log(userDetail);
          this.loaderService.hide();
          this.userDataService.changeuser(userDetail);
        } catch {
          this.loaderService.hide();
        }
      }) 
      .catch((error) => {
        this.loaderService.hide();
        console.log(error);
      });
    }else{
      this.loaderService.hide();
      console.log(this.userDetails.verificationCode , Number(verifyCode))
      this.alertnotification.errorAlert("Verification code is not valid");
      document.getElementById("openModal").click();
    }
  }
  openImage(template1: TemplateRef<any>, url) {
    if ((url && url.includes("https")) || url.includes("http")) {
      this.viewImage = url;
      // return url
      this.modalRef = this.modalService.show(template1, {
        class: "modal-show-image",
      });
    }
  }

  decline(): void {
    this.modalRef.hide();
  }

  openFile() {
    window.open("assets/rightrent.pdf");
  }
}
