import { Component, OnInit } from '@angular/core';

import { ApiServiceService } from 'src/app/core/services/api-service';
import { AlertNotification } from 'src/app/core/alertNotification/alertNotification';
import { Router } from '@angular/router';
import { SubscriberFunction } from "../../shared/helpers/subscriberFunction";


@Component({
  selector: 'app-shortlisted',
  templateUrl: './shortlisted.component.html',
  styleUrls: ['./shortlisted.component.scss']
})
export class ShortlistedComponent implements OnInit {

  shortListArray: any[] = [];
  p: number = 1;

  totalPage: any;
  rowLimit: any;
  pagenum: any = 1;
  totalCount: any = 0;
  totalReturn: any;
  sortDataArray = [];
  userDetails = null;

  constructor(private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private router: Router,
    private SubscriberFunction: SubscriberFunction) { }


  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
    this.getPropertiesData();
  }

  getPropertiesData() {
    console.log('step 1')
    this.shortListArray = [];
    this.apiservice.postRequest(`favorite.php?all=true`, {email: this.userDetails.email}).subscribe((response: any) => {
      Object.keys(response).map((x) => {
        if (x == 'totalpage') {
          this.totalPage = response[x];
        } else if (x == 'rowlimit') {
          this.rowLimit = response[x];
        } else if (x == 'pagenum') {
        } else if (x == 'totalcount') {
          this.totalCount = response[x];
        } else if (x == 'totalreturn') {
          this.totalReturn = response[x];
        } else {
          response[x]['distance'] =  this.calculateDistance(response[x].lat, response[x].lng)
          this.shortListArray.push(response[x]);
        }
      })
      this.sortDataArray = this.shortListArray;

      if(localStorage.getItem("pageInfo")) {
        let pageInfo =  JSON.parse(localStorage.getItem("pageInfo"));
        this.p = pageInfo.page;
        setTimeout(() => {
          this.scrollToParticularPageIndex(pageInfo.propertyIndex)
        }, 500)
      }
    },
      error => {
        // console.log(error.error);
        this.alertnotification.errorAlert(error.error.message);
      });

  }

  checkImageUrl(url) {
    if (url.includes("https") || url.includes("http")) {
      return url
    } else {
      return ('assets/images/bg1.png');
    }
  }

  viewProperty(object, index) {
    localStorage.setItem("pageInfo", JSON.stringify({
      page: this.p,
      propertyIndex: index,
      fromPage: 'shortlisted'
    }));
    localStorage.setItem("propertyDetail", JSON.stringify(object));
    this.router.navigate(["/user/property-details"]);
  }

  clickPagination() {
    window.scroll(0, 0);
  }

  calculateDistance(houseLat, houseLong) {
    let userLat = this.userDetails.latitude;
    let userLong = this.userDetails.longitude;
    if ((userLat == houseLat) && (userLong == houseLong)) {
      return 0;
    }
    else {
      var raduserLat = Math.PI * userLat/180;
      var radhouseLat = Math.PI * houseLat/180;
      var theta = userLong-houseLong;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(raduserLat) * Math.sin(radhouseLat) + Math.cos(raduserLat) * Math.cos(radhouseLat) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = (dist * 60 * 1.1515)+0.1;
      dist = Math.round((dist+ 0.16) * 10) / 10;
      return dist.toFixed(1);
    }
  }

  scrollToParticularPageIndex(id){
    const element = document.getElementById(id); // id of the scroll to element
    element.scrollIntoView();
    if(localStorage.getItem("pageInfo")) {
      localStorage.removeItem("pageInfo");
    }
  }
}
