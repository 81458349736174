import { Injectable } from '@angular/core';
import {
    HTTP_INTERCEPTORS, 
    HttpEvent, 
    HttpHandler, 
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements HttpInterceptor {
  constructor(private loaderService: NgxSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let _url = req.url;
    // console.log(_url.split('/')) 
    // if(_url.split('/')[5] && (_url.split('/')[5] == 'getchatuser' || _url.split('/')[5] == 'sendmessagepush')){
    //   return next.handle(req).pipe(tap((event: HttpEvent<any>) => { 
    //     if (event instanceof HttpResponse) {
    //       this.loaderService.hide();
    //     }
    //   },
    //     (err: any) => {
    //       this.loaderService.hide();
    //   }));
    // }else if( _url.split('/')[5] == 'uploadPhoto' ){
    //   this.loaderService.show();
    //   return next.handle(req).pipe(tap((event: HttpEvent<any>) => { 
    //     if (event instanceof HttpResponse) {
    //       // this.loaderService.hide();
    //     }
    //   },
    //     (err: any) => {
    //       // this.loaderService.hide();
    //   }));
    // }else{
      this.loaderService.show();
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => { 
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
      },
        (err: any) => {
          this.loaderService.hide();
      }));
    // }
    
  }

}