import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class CanActivateAdmin {

  constructor( private router: Router ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(localStorage.getItem('User')){
      return true;
    }else{
      localStorage.setItem("returnUrl" , JSON.stringify(state.url));
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    
  }
}