import {Pipe, PipeTransform } from "@angular/core";
import { SubscriberFunction } from "../helpers/subscriberFunction";

@Pipe({
    name : "listSorting"
})

export class ListSortingPipe implements PipeTransform {

    itemsArray
    constructor(
        private SubscriberFunction : SubscriberFunction
    ) {}

    transform(items, text: string):any {
        // console.log(text.length)
        this.itemsArray = items;
        if(text){
            let user = items.filter((x) => {
                return(
                    (x.title && x.title.toLowerCase().indexOf(text.toLowerCase()) >= 0) ||
                    (x.desc && x.desc.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                )
            })
            this.SubscriberFunction.updateSearchCount(user.length);
            return user;
        }else{
            console.log(this.itemsArray)
            this.SubscriberFunction.updateSearchCount(this.itemsArray.length);
            return this.itemsArray;
        }
    }
}