import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Options } from 'ng5-slider';
import { ApiServiceService } from 'src/app/core/services/api-service';

@Component({
  selector: 'app-tabs-headers',
  templateUrl: './tabs-headers.component.html',
  styleUrls: ['./tabs-headers.component.scss']
})
export class TabsHeadersComponent implements OnInit {

  @Input() properties;
  @Input() listName;
  @Output() searchEvent = new EventEmitter<string>();
  @Output() filterEvent = new EventEmitter<any>();
  searchProperty = null;
  p: number = 1;
  userDetails = null;
  selectedCity

  filters = {
    miles: 1000,
    closestFirst: false,
    bedroomFilter: {
      "min": 0,
      "max": 9,
      "range": 'range'
    },
    lat:'',
    long:''
  };

  options: Options = {
    floor: 0,
    ceil: 1000,
    showSelectionBar: true,
    selectionBarGradient: {
      from: 'white',
      to: '#0db9f0'
    }
  };
  areaOfInterestList

  constructor(private apiservice: ApiServiceService,) {

  }

  ngOnInit(): void {
    this.getAreaOfInterest()
  }

  getAreaOfInterest() {
    var data = {
    }
    this.apiservice.postRequestToCore('web/getCityList.php',data).subscribe((result:any) => {
      // console.log(result.data);
      this.areaOfInterestList = result.data;
    },
    error => {
      console.log(error)
    });
  }

  sendSearchText() {
    this.searchEvent.emit(this.searchProperty)
  }


  onchangeCheckbox(evt) {
    if (evt.target.checked) {
      this.filters.closestFirst = true;
    } else {
      this.filters.closestFirst = false;
    }
  }


  onChangeMiles(evt) {
    this.filters.miles = evt.value;
  }

  onChangeBedType() {
    let bedroomDetails = this.filters.bedroomFilter;
    if (bedroomDetails.min === bedroomDetails.max) {
      this.filters.bedroomFilter.range = 'notRange';
    } else {
      this.filters.bedroomFilter.range = 'range';
    }
  }

  isDisabled(value, type) {
    let bedroomDetails = this.filters.bedroomFilter;
    if (type == 'min' && value > bedroomDetails.max) {
      return true;
    } else if (type == 'max' && value < bedroomDetails.min) {
      return true;
    } else {
      return false;
    }
  }


  onClickReset() {
    this.filters = {
      miles: 1000,
      closestFirst: false,
      bedroomFilter: {
        "min": 0,
        "max": 9,
        "range": 'range'
      },
      lat:'',
      long:''
    }
    this.searchProperty = null;
    this.sendFiltersData();
    this.sendSearchText();
  }

  onClickSave() {
    this.sendFiltersData()
  }


  sendFiltersData(){
    this.filterEvent.emit(this.filters);
  }

  onChange(city){
    if(city && city.lat && city.lng) {
      this.filters.lat = city.lat;
      this.filters.long = city.lng;
      // this.filterEvent.emit(this.filters);
    } else {
      return;
    }
  }


}
