import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import  { MustMatch } from 'src/app/shared/helpers/password-must-match';
import { FirebaseQueryService } from 'src/app/core/services/firebase-query';
import * as firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertNotification } from 'src/app/core/alertNotification/alertNotification';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  profileForm: FormGroup;
  userDetails: any;

  constructor(private formBuilder : FormBuilder,
    private firebaseservice : FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private alertnotification : AlertNotification,
    private router: Router) { 
      if(localStorage.getItem("userDetail")) {
        this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
      }
    }

  ngOnInit(): void {
    this.initialisePasswordResetForm()
  }


  initialisePasswordResetForm(){
    this.profileForm = this.formBuilder.group({
      oldPassword: [null,[Validators.required]],
      newPassword: [null,[Validators.required, Validators.minLength(6)]],
      repeatPassword: [null,[Validators.required]],
    }, {
      validator: MustMatch("newPassword", "repeatPassword"),
    });
  }
 
  onSubmit(){
    if(this.profileForm.invalid) {
      return;
    } else{
      this.loaderService.show()
      var user = firebase.auth().currentUser;
      var user = firebase.auth().currentUser;
      var credentials = firebase.auth.EmailAuthProvider.credential(
        this.userDetails.email,
        this.profileForm.value.oldPassword
      );
      user.reauthenticateWithCredential(credentials).then((response)=> {
        console.log(response);
        // User re-authenticated.
        user.updatePassword(this.profileForm.value.newPassword).then((res)=> {
          this.loaderService.hide();
          this.alertnotification.successAlert('Password changed successfully');
          localStorage.clear();
          this.router.navigate(['/login']);
        }).catch((error)=> {
          this.loaderService.hide();
          this.alertnotification.errorAlert(error);
          return;
        });
      }).catch((error)=> {
        this.loaderService.hide();
        this.alertnotification.errorAlert(error);
        return;
      });

      

      // this.firebaseservice.signInUser(this.userDetails.email,this.profileForm.value.oldPassword).then(async  (response)=>{
      //   console.log(response);
      //   this.loaderService.hide();
      // })
      // .catch((error)=>{
      //   this.loaderService.hide();
      //   this.alertnotification.errorAlert(error);
      //   return;
      // });
    }
  }
}
