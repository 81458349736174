import { Component, OnInit} from '@angular/core';
import { ApiServiceService } from 'src/app/core/services/api-service';
import { AlertNotification } from 'src/app/core/alertNotification/alertNotification';
import { Router } from '@angular/router';
import { SubscriberFunction } from "../../shared/helpers/subscriberFunction";
import * as _ from "lodash";
@Component({
  selector: 'app-property-lisitng',
  templateUrl: './property-lisitng.component.html',
  styleUrls: ['./property-lisitng.component.scss']
})
export class PropertyLisitngComponent implements OnInit {

  filterCollapse: boolean = false;
  propertiesListArray: any[] = [];
  p: number = 1;
  totalPage: any;
  rowLimit: any;
  pagenum: any = 1;
  totalCount: any = 0;
  totalReturn: any;
  sortDataArray = [];

  filters = {
    miles: 1000,
    closestFirst: true,
    bedroomFilter: {
      "min": 0,
      "max": 9,
      "range": 'range'
    },
    lat: '',
    long: ''
  };

  searchProperty = null;
  TotalCount = 0;
  userDetails = null;

  constructor(
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private router: Router,
    private SubscriberFunction: SubscriberFunction
  ) {

    this.SubscriberFunction.currentCount.subscribe((value) => {
      // console.log(value);
      window.setTimeout(() => {
        this.totalCount = value;
      }, 999);
      // console.log(this.totalCount);
    })
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
   
    if( typeof this.userDetails.isProfileSaved != 'undefined' && this.userDetails.isProfileSaved == false ){
      if(this.userDetails.isPhoneVerified == true ){
        this.alertnotification.errorAlert('A completed profile information is requried in order to view available properties');
      }
      this.router.navigate(['/user/edit-profile']);
    }else if( typeof this.userDetails.status != 'undefined' && this.userDetails.status == 'suspended' ){
      this.alertnotification.errorAlert("Your account is currently suspended because you haven't completed your profile information. Please complete your profile to activate your account.");
      this.router.navigate(['/user/edit-profile']);
    }

    this.getPropertiesData(0);
  }

  getPropertiesData(number) {
    this.apiservice.getRequest(`/api.php?all=true&latest=true`, {}, true).subscribe((response: any) => {
      this.propertiesListArray = [];
      Object.keys(response).map((x:any) => {
        if (x == 'totalpage') {
          this.totalPage = response[x];
        } else if (x == 'rowlimit') {
          this.rowLimit = response[x];
        } else if (x == 'pagenum') {
        } else if (x == 'totalcount') {
          this.totalCount = response[x];
        } else if (x == 'totalreturn') {
          this.totalReturn = response[x];
        } else {
          response[x]['distance'] =  this.calculateDistance(response[x].lat, response[x].lng, this.userDetails.latitude, this.userDetails.longitude)
          response[x]['distanceFromCity'] =  null;
          let allInfo = response[x]?.title + response[x]?.desc + response[x]?.property_address + response[x]?.feature
          allInfo = allInfo
          if(allInfo.includes(55)){
            response[x]['over55'] =  true;
          }
          this.propertiesListArray.push(response[x]);
        }
      })
      this.sortDataArray = this.propertiesListArray;
      
      if(localStorage.getItem("pageInfo")) {
        let pageInfo =  JSON.parse(localStorage.getItem("pageInfo"));
        this.p = pageInfo.page;
        setTimeout(() => {
          this.scrollToParticularPageIndex(pageInfo.propertyIndex)
        }, 500);
      }

    },
      error => {
        // console.log(error.error);
        this.alertnotification.errorAlert(error.error.message);
      });

  }

  clickPagination() {
    window.scroll(0, 0);
    console.log(this.propertiesListArray)
  }

  viewProperty(object,index) {
    localStorage.setItem("propertyDetail", JSON.stringify(object));
    localStorage.setItem("pageInfo", JSON.stringify({
      page: this.p,
      propertyIndex: index,
      fromPage: 'properties'
    }));
    this.router.navigate(["/user/property-details"]);
  }


  checkImageUrl(url) {
    if (url.includes("https") || url.includes("http")) {
      return url
    } else {
      return ('assets/images/bg1.png');
    }
  }


  receiveSearchText($event) {
    this.p=1
    this.searchProperty = $event
  }

  receiveFilterData($event) {
    this.p = 1;
    this.filters = $event;
    if (this.filters.bedroomFilter.range == 'range') {
      this.propertiesListArray = this.sortDataArray.filter((x) => {
        if (x.roomtype >= this.filters.bedroomFilter.min && x.roomtype <= this.filters.bedroomFilter.max) {
          return x;
        }
      })
    } else {
      this.propertiesListArray = this.sortDataArray.filter((x) => {
        if (x.roomtype == this.filters.bedroomFilter.min) {
          return x;
        }
      })
    }
    
    if(this.filters.miles<1000){
      console.log(this.filters.miles)
      this.propertiesListArray = _.filter(this.propertiesListArray, (o)=> { 
        return (o.distance <= this.filters.miles); 
      });
    }

    if(this.filters.lat && this.filters.long){ 
      _.map(this.propertiesListArray,(obj)=>{
        if(obj.lat && obj.lng){
          obj.distance =  this.calculateDistance(obj.lat, obj.lng, this.filters.lat, this.filters.long)
        }
      })
      this.propertiesListArray = _.sortBy(this.propertiesListArray, [function(o) { return o.distance; }])
    }

    if(this.filters.closestFirst){
      this.propertiesListArray = _.sortBy(this.propertiesListArray, [function(o) { return o.distance; }])
    }

    
  }

  calculateDistance(houseLat, houseLong, userLat, userLong) {
    if ((userLat == houseLat) && (userLong == houseLong)) {
      return 0;
    }
    else {
      var raduserLat = Math.PI * userLat/180;
      var radhouseLat = Math.PI * houseLat/180;
      var theta = userLong-houseLong;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(raduserLat) * Math.sin(radhouseLat) + Math.cos(raduserLat) * Math.cos(radhouseLat) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = Math.round((dist+ 0.16) * 10) / 10;
      return dist.toFixed(1);
    }
  }

  scrollToParticularPageIndex(id){
    const element = document.getElementById(id); // id of the scroll to element
    element.scrollIntoView();
    if(localStorage.getItem("pageInfo")) {
      localStorage.removeItem("pageInfo");
    }
  }

}
