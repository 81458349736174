import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';

import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import { Ng5SliderModule } from 'ng5-slider';

import { TruncatePipe } from "./helpers/truncatePipe";
import { ListSortingPipe } from "./helpers/listSorting";


import { HeaderComponent } from '../layout/common/header/header.component';
import { FooterComponent } from '../layout/common/footer/footer.component';

@NgModule({
  declarations: [    
    HeaderComponent,
    FooterComponent,
    TruncatePipe,
    ListSortingPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    NgSelectModule,
    RouterModule,
    PasswordStrengthMeterModule,
    Ng5SliderModule,
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
  ],
  exports : [
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BsDatepickerModule,
    NgxSpinnerModule,
    NgSelectModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    PasswordStrengthMeterModule,
    TruncatePipe,
    Ng5SliderModule,
    ListSortingPipe,
    ModalModule,
    TypeaheadModule
  ],
  
})
export class SharedModule { }
