<header [ngClass]="{'header_class':checkRoute()}">
    <div class="container">
        <div class="header_inner">
            <div class="logo">
                <a routerLink="/"><img src="assets/images/live-tech-logo.svg"></a>
            </div>
            <div class="btns">
                <!-- <button class="btn"><img src="assets/images/search.svg"></button> -->
                <div class="nav-item dropdown hidden-caret profile-dropdown">
                    <a aria-expanded="false" class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#">
                        <div class="avatar-sm">
                            <ng-container *ngIf="!userDetails?.imgProfile">
                                <img alt="..." class="avatar-img rounded-circle" src="assets/images/user.png">
                            </ng-container>
                            <ng-container *ngIf="userDetails?.imgProfile">
                                <img alt="..." class="avatar-img rounded-circle" [src]="userDetails?.imgProfile">
                            </ng-container>
                        </div>
                        <div class="avt_txt"><span>{{userDetails.firstName || ''}} {{userDetails.lastName || ''}}
                            </span><img src="assets/images/arrow.svg"></div>
                    </a>
                    <ul class="dropdown-menu dropdown-user animated fadeIn">
                        <div class="dropdown-user-scroll scrollbar-outer">
                            <li><a (click)="logoutUser()" class="dropdown-item">
                                    Logout</a>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>