<app-tabs-headers [properties]="0" [listName]="'settings'"></app-tabs-headers>
<section class="wkSetting">
    <div class="container">
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                            aria-controls="collapseOne">
                            Help
                        </button>
                    </h5>
                </div>
        
                <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                       <ul>
                           <li><a href="https://m.facebook.com/My-Social-Housing-100197514902026/?modal=admin_todo_tour" target="_blank">Facebook</a></li>
                           <li><a href="https://www.instagram.com/mysocialhousing/" target="_blank">Instagram</a></li>
                           <li><a href="https://mobile.twitter.com/mysocialhousing" target="_blank">Twitter</a></li>
                           <li><a href="mailto:help@mysocialhousing.co.uk?Subject=My Social Housing Help" target="_blank">Send Mail</a></li>
                           <li><a href="tel:02037552735"  target="_blank">Call Us</a></li>
                       </ul>
                    </div>
                </div>
            </div>
            <div class="card ">
                <div class="card-header" id="headingFaq">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">
                           <a href="https://mysocialhousing.co.uk/frequently-asked-questions/"  target="_blank">FAQs</a>  
                        </button>
                    </h5>
                </div>

            </div>
            <div class="card ">
                <div class="card-header" id="headingPrivacy">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">
                           <a href="https://mysocialhousing.co.uk/privacy-policy/"  target="_blank">Privacy Policy</a>  
                        </button>
                    </h5>
                </div>

            </div>
            <div class="card ">
                <div class="card-header" id="headingTermsofuse">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">
                           <a href="https://mysocialhousing.co.uk/terms-of-use/"  target="_blank">Terms of Use</a>  
                        </button>
                    </h5>
                </div>

            </div>

            <div class="card">
                <div class="card-header" id="headingChangePassword">
                    <h5 class="mb-0">
                        <button class="btn btn-link" type="button">
                            Change Password
                        </button>
                    </h5>
                </div>
        
                <div>
                    <section class="form_box">
                        <div class="card-body">
                            <div class="container">
                                <div class="form_inner">
                                    <form [formGroup]="profileForm" (submit)="onSubmit()">
                                        <div class="col-sm-12 wh_input">
                                            <label>Old Password <span class="danger">*</span></label>
                                            <input type="password" class="form-control" formControlName="oldPassword" placeholder="Old Password" />
                                            <div class="has-error" *ngIf="(profileForm.controls.oldPassword.dirty || profileForm.controls.oldPassword.touched) && profileForm.controls.oldPassword.errors">
                                                <small *ngIf="profileForm.controls.oldPassword.errors.required">Old Password is required</small>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 wh_input">
                                            <label>New Password <span class="danger">*</span></label>
                                            <input type="password" class="form-control" formControlName="newPassword" placeholder="New Password" />
                                            <div class="has-error" *ngIf="(profileForm.controls.newPassword.dirty || profileForm.controls.newPassword.touched) && profileForm.controls.newPassword.errors">
                                                <small *ngIf="profileForm.controls.newPassword.errors.required">New Password is required</small>
                                                <div *ngIf="profileForm.controls.newPassword.errors.minlength && !profileForm.controls.newPassword.errors.required">
                                                    New Password should be 6 characters long</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 wh_input">
                                            <label>Repeat Password <span class="danger">*</span></label>
                                            <input type="password" class="form-control" formControlName="repeatPassword" placeholder="Repeat Password" />
                                            <div class="has-error" *ngIf="(profileForm.controls.repeatPassword.dirty || profileForm.controls.repeatPassword.touched) && profileForm.controls.repeatPassword.errors">
                                                <small *ngIf="profileForm.controls.repeatPassword.errors.required">Repeat Password is required</small>
                                                <small *ngIf="profileForm.controls.repeatPassword.errors.mustMatch">Confirm Passwords must match</small>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <button type="submit" class="btn btn-green ">Change Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>