import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private userSource = new BehaviorSubject('default user');
  currentuser = this.userSource.asObservable();

  constructor() { }

  changeuser(user: any) {
    this.userSource.next(user)
  }
}
