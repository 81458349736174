import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/** firebase */
import {firebaseConfig} from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

/** Core Services **/
import { ApiServiceService } from '../app/core/services/api-service';
import { FirebaseQueryService } from '../app/core/services/firebase-query';
import { AlertNotification } from '../app/core/alertNotification/alertNotification';
// import { HttpErrorInterceptor } from '../app/core/interceptor/interceptor';
import { HttpErrorInterceptor } from '../app/core/interceptor-files/interceptor';
import { CanActivateLogin } from '../app/core/guards/authguard';
import { CanActivateAdmin } from '../app/core/guards/loginguard';

import { SubscriberFunction } from "./shared/helpers/subscriberFunction";

/** loader */
import { LoaderService } from '../app/core/common-service/loader-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**shared module */
import { SharedModule } from './shared/shared.module';
import { UserModule } from "./user/user.module";
import { HomeComponent } from './layout/home/home.component';
import { ApplicationformComponent } from './layout/applicationform/applicationform.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ApplicationformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(firebaseConfig),
    BrowserAnimationsModule,
    NgbModule,
    ModalModule,
    SharedModule,
    UserModule
  ],
  providers: [
    ApiServiceService,
    FirebaseQueryService,
    AlertNotification,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor , multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderService , multi: true},
    CanActivateAdmin,
    CanActivateLogin,
    SubscriberFunction
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
