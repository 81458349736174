<app-tabs-headers [properties]="0" [listName]="'notifications'"  ></app-tabs-headers>

<section class="WKSec">
    <div class="container">
        <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Message</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of notificationsArray; let i= index">
                <th>{{data.messageDetails.title}}</th>
                <td>{{data.messageDetails.message}}</td>
                <td>{{data.createdAt | date: "dd/MM/yyyy"}}</td>
              </tr>
            </tbody>
        </table>
    </div>
</section>
