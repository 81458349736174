import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AlertNotification } from 'src/app/core/alertNotification/alertNotification';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { Subscription } from 'rxjs/Subscription'
@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit, OnDestroy {

  userDetails  = null;
  $userDataSubscription: Subscription;

  constructor(
    private router : Router,
    private alertnotification : AlertNotification,
    private userDataService: UserDataService
  ) {
    if(localStorage.getItem("userDetail") && localStorage.getItem("userDetail") !== 'undefined'){
      this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
    }
   }

  ngOnInit(): void {
    this.$userDataSubscription = this.userDataService.currentuser.subscribe((data:any)=>{
      if(data){
        this.updateProfileData();
      }
    })
  }

  checkRoute() {
    // console.log(window.location.pathname);
    if(window.location.pathname == '/user/property-details') {
      return true;
    }else{
      return false;
    }
   }

  logoutUser(){
    Swal.fire({
      title: 'Do you want to logout ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    })
    .then((result) => {
      if(result.value == true){
        localStorage.clear();
        this.alertnotification.successAlert('Logout Successfully');
        this.router.navigate(['/']);
      }else{
        return;
      }
    });
  }

  updateProfileData(){
    this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
  }

  ngOnDestroy(){
    this.$userDataSubscription.unsubscribe();
  }



}
