import { Component, OnInit } from '@angular/core';
import { FirebaseQueryService } from 'src/app/core/services/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDataService } from 'src/app/core/services/user-data.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  DB = firebase.firestore(); 
  constructor(private firebaseservice : FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private userDataService: UserDataService
  ) {

   }

  ngOnInit(): void {
    if(localStorage.getItem('User')){
      let response = JSON.parse(localStorage.getItem('User'));
      if(response && response.user && response.user.uid) {
        try {
          this.getUserDetails(response.user.uid);
        }catch(e) {
          this.loaderService.hide();
          console.log(e)
          return;
        }
      }
    }
  }
  
  
  getUserDetails(id) {
    var records1 = [];
    return new Promise((resolve,reject) => {
      this.firebaseservice.getUserDetail(id).then((querySnapshot) =>{
        querySnapshot.forEach(function(doc) {
          var data = doc.data();
          data['id'] = doc.id
          records1.push(data);
        });
        localStorage.setItem("userDetail" ,JSON.stringify(records1[0]));
        this.userDataService.changeuser(records1[0]);
        // this.checkUserUpdate(records1[0].id);
        this.loaderService.hide();
        resolve(true);
      })
      .catch(function(error) {
        this.loaderService.hide();
        console.log("Error getting documents: ", error);
        reject(false);
      }.bind(this));
    })
  }


  checkUserUpdate(id){
    console.log(id)
    this.DB.collection("users").where("userId","==",id)
    .onSnapshot((querySnapshot) => {
        var users = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        console.log(users);
        debugger
    });
  }

}
