import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  HostListener,
} from "@angular/core";
import { Subscription } from "rxjs";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Router, ActivatedRoute } from "@angular/router";
import { FirebaseQueryService } from "../../core/services/firebase-query";
import { AlertNotification } from "../../core/alertNotification/alertNotification";
import { NgxSpinnerService } from "ngx-spinner";
import { UserDataService } from "../../core/services/user-data.service";
import { ApiServiceService } from "../../core/services/api-service";
import * as moment from "moment";
import * as _ from "lodash";
import Swal from "sweetalert2";
import { PostalCodeValidator } from "../../shared/helpers/postcode-match";

@Component({
  selector: "app-applicationform",
  templateUrl: "./applicationform.component.html",
  styleUrls: ["./applicationform.component.scss"],
})
export class ApplicationformComponent implements OnInit {
  @HostListener("window:message", ["$event"])
  $userDataSubscription: Subscription;
  relationshipOption = [
    "Wife",
    "Husband",
    "Partner",
    "Daughter",
    "Son",
    "Friend",
    "Grandmother",
    "Grandfather",
    "Brother",
    "Sister",
    "other",
  ];
  bedroomSize = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  currentAccommodation = [
    "Housing Association",
    "Council",
    "Private Landlord",
    "Hostel",
    "B&B",
    "NFA",
    "Temporary Accomodation",
    "Stayin with family/friends",
    "Other",
  ];
  currentTenancyType = [
    "Temporary Accommodation",
    "Social Housing",
    "Private Tenancy",
    "Suported Housing",
    "Living with friends and family",
  ];
  showFamilyForms: boolean = true;
  updateProfileForm: any;
  protectedPass: any;
  publicformVisibility: boolean = false;
  additionalMembers: FormArray;
  showFamilyDetails: boolean = false;
  userDetails = null;
  id_Proof_Ary = [];
  inc_Proof_Ary = [];
  bank_Proof_Ary = [];
  medical_Proof_Ary = [];
  referenceAgents = [];
  passcode;
  userData: any;
  defaultImage = "assets/images/photo.png";
  modalRef: BsModalRef;
  viewImage: any;
  currentDate = new Date();
  assignedStaff = {};
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private firebaseservice: FirebaseQueryService,
    private alertnotification: AlertNotification,
    private loaderService: NgxSpinnerService,
    private userDataService: UserDataService,
    private apiservice: ApiServiceService,
    private modalService: BsModalService,
    private el: ElementRef
  ) {
    this.protectedPass = this.formBuilder.group({
      password: [""],
    });
  }

  ngOnInit(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("email");
    const name = urlParams.get("name");
    const uid = urlParams.get("uid");
    this.assignedStaff = {
      email,
      name,
      uid,
    };

    this.updateProfileForm = this.formBuilder.group({
      imgProfile: [""],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required]],
      address: ["", [Validators.required]],
      postCode: ["", [Validators.required, PostalCodeValidator]],
      dateOfBirth: ["", [Validators.required]],
      referringAgent: ["", [Validators.required]],
      landlordName: [
        "",
        !this.assignedStaff["email"]
          ? Validators.required
          : Validators.nullValidator,
      ],
      landlordPhoneNumber: [
        "",
        !this.assignedStaff["email"]
          ? [Validators.required, Validators.pattern(/^[0-9]\d*$/)]
          : Validators.nullValidator,
      ],
      landlordEmail: [""],
      firstNameFC: [""],
      lastNameFC: [""],
      dateOfBirthFC: [""],
      relationshipToYouFC: [""],
      bedroomSize: ["", [Validators.required]],
      currentAccommodation: ["", [Validators.required]],
      currentTenancyType: ["", [Validators.required]],
      isRentArrearsProvided: [false],
      isMedicalDetailsProvided: [false],
      isMedicalProof: [false],
      isASBDetailsProvided: [false],
      //email1 : ["",[Validators.required,Validators.email]],
      //email2 : [""],
      //phoneNumber1 : ["",[Validators.required,Validators.pattern(/^[0-9]\d*$/)]],
      //phoneNumber2 : [""],
      medicalDetails: [""],
      asbDetails: [""],
      rentArrearsAmount: [0, [Validators.pattern(/^[0-9]\d*$/)]],
      additionalMembers: this.formBuilder.array([]),
      arrImgIdProof: this.formBuilder.array([]),
      arrImgIncomeProof: this.formBuilder.array([]),
      arrImgBankStatements: this.formBuilder.array([]),
      arrImgMedicalProof: this.formBuilder.array([]),
      addressHistory2: [
        "",
        !this.assignedStaff["email"]
          ? Validators.required
          : Validators.nullValidator,
      ],
      addressHistory3: [
        "",
        !this.assignedStaff["email"]
          ? Validators.required
          : Validators.nullValidator,
      ],
      addressHistory4: [
        "",
        !this.assignedStaff["email"]
          ? Validators.required
          : Validators.nullValidator,
      ],
      addressHistory5: [
        "",
        !this.assignedStaff["email"]
          ? Validators.required
          : Validators.nullValidator,
      ],
      employmentDetails: [""],
      applicantDangerToStaff: ["", [Validators.required]],
      income: ["", [Validators.required]],
      nationalInsuranceNumber: ["", [Validators.required]],
      noOfYearsAtAdress: ["", [Validators.required]],
      noOfMonthsAtAdress: ["", [Validators.required]],
      accommodationRequired: ["", [Validators.required]],
      firstNameRA: ["", [Validators.required]],
      lastNameRA: ["", [Validators.required]],
      emailRA: ["", [Validators.required, Validators.email]],
      phoneNumberRA: [
        "",
        [Validators.required, Validators.pattern(/^[0-9]\d*$/)],
      ],
      agreeRA: [false, Validators.requiredTrue],
      appTermAndCondition: [false, Validators.requiredTrue],
      privacyPolicy: [false, Validators.requiredTrue],
    });
    this.setUserDetailsForForm();

    if (sessionStorage.getItem(this.passcode)) {
      this.publicformVisibility = true;
    }
  }

  fetchUserDetails() {
    this.$userDataSubscription = this.userDataService.currentuser.subscribe(
      (data) => {
        this.userData = data;
        if (this.userData && sessionStorage.getItem("userDetail")) {
          this.userDetails = JSON.parse(sessionStorage.getItem("userDetail"));
          const additionalMembers = this.updateProfileForm.get(
            "additionalMembers"
          ) as FormArray;
          additionalMembers.clear();
          this.id_Proof_Ary = [];
          this.inc_Proof_Ary = [];
          this.bank_Proof_Ary = [];
          this.medical_Proof_Ary = [];
          this.setUserDetailsForForm();
        }
      }
    );
  }

  setUserDetailsForForm() {
    const additionalMembers = this.updateProfileForm.get(
      "additionalMembers"
    ) as FormArray;
    this.updateProfileForm.additionalMembers = [];
    for (let index = 0; index <= 4; index++) {
      additionalMembers.push(this.createItem(null));
      this.updateProfileForm.additionalMembers.push(
        this.createDefaultMembers()
      );
    }
  }

  add(x): FormGroup {
    return this.formBuilder.group(x);
  }

  createItem(data): FormGroup {
    return this.formBuilder.group({
      firstName: [data && data.firstName ? data.firstName : ""],
      lastName: [data && data.lastName ? data.lastName : ""],
      dateOfBirth: [
        data && data.dateOfBirth ? moment(data.dateOfBirth).toDate() : "",
      ],
      relationshipToYou: [
        data && data.relationshipToYou ? data.relationshipToYou : "",
      ],
      imgIdProofMember: [
        data && data.imgIdProofMember ? data.imgIdProofMember : "",
      ],
      imgIncomeProofMember: [
        data && data.imgIncomeProofMember ? data.imgIncomeProofMember : "",
      ],
    });
  }

  createDefaultMembers() {
    return {
      dateOfBirth: "",
      firstName: "",
      imgIdProofMember: "",
      imgIncomeProofMember: "",
      lastName: "",
      relationshipToYou: "",
    };
  }

  addItem(): void {
    this.additionalMembers = this.updateProfileForm.get(
      "additionalMembers"
    ) as FormArray;
    this.additionalMembers.push(this.createItem(null));
    console.log("add clicked");
  }

  showAddFamilyForms() {
    this.showFamilyForms = !this.showFamilyForms;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.updateProfileForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
    return invalid;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onSubmit(checkValidation) {
    console.log(this.updateProfileForm.value);
    console.log(this.updateProfileForm.valid);
    //console.log(this.updateProfileForm.controls.isMedicalProof.value);
    if (checkValidation)
      //Logic for addition family members
      this.updateProfileForm.value["additionalMembers"].forEach((member, i) => {
        Object.keys(member).forEach((memberField: any) => {
          if (!member[memberField]) delete member[memberField];
        });
      });

    Object.keys(this.updateProfileForm.value).forEach((field: any) => {
      if (
        !this.updateProfileForm.value[field] ||
        this.updateProfileForm.value[field].length === 0
      )
        delete this.updateProfileForm.value[field];
    });

    if (this.updateProfileForm.valid) {
      this.updateProfileForm.value["arrImgIdProof"] = [];
      this.updateProfileForm.value["arrImgIncomeProof"] = [];
      this.updateProfileForm.value["arrImgBankStatements"] = [];
      this.updateProfileForm.value["arrImgMedicalProof"] = [];

      if (
        this.updateProfileForm.value.noOfYearsAtAdress &&
        this.updateProfileForm.value.noOfYearsAtAdress >= 0
      ) {
        let noofyears =
          this.updateProfileForm.value.noOfYearsAtAdress + " Years";
        this.updateProfileForm.value.noOfYearsAtAdress = noofyears;
      }

      if (
        this.updateProfileForm.value.noOfMonthsAtAdress &&
        this.updateProfileForm.value.noOfMonthsAtAdress >= 0
      ) {
        let noofmonths =
          this.updateProfileForm.value.noOfMonthsAtAdress + " Months";
        this.updateProfileForm.value.noOfMonthsAtAdress = noofmonths;
      }

      if (this.id_Proof_Ary && this.id_Proof_Ary.length > 0) {
        this.id_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgIdProof"].push(x);
        });
      }
      if (this.inc_Proof_Ary && this.inc_Proof_Ary.length > 0) {
        this.inc_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgIncomeProof"].push(x);
        });
      }
      if (this.bank_Proof_Ary && this.bank_Proof_Ary.length > 0) {
        this.bank_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgBankStatements"].push(x);
        });
      }

      if (this.medical_Proof_Ary && this.medical_Proof_Ary.length > 0) {
        this.medical_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgMedicalProof"].push(x);
        });
      }

      if (
        !this.inc_Proof_Ary.length ||
        !this.bank_Proof_Ary.length ||
        !this.id_Proof_Ary.length
      ) {
        this.alertnotification.errorAlert(
          "Please upload your proof of ID, proof of income and bank statement"
        );
        return;
      }

      this.updateProfileForm.value["public"] = true;
      this.updateProfileForm.value["createdAt"] = new Date();
      this.updateProfileForm.value = {
        ...this.updateProfileForm.value,
        assignedStaff: { ...this.assignedStaff, assignedDate: new Date() },
      };

      console.log("2------>", this.updateProfileForm.value);
      this.firebaseservice
        .createPublicRecord(this.updateProfileForm.value)
        .then(async (records) => {
          console.log("Your form has been submitted successfully");
          this.alertnotification.successAlert("successfully updated");
          this.loaderService.hide();
          sessionStorage.removeItem(this.passcode);
          this.router.navigate([""]);
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
        });
    } else {
      console.log(this.updateProfileForm.value);

      this.updateProfileForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      console.log("invalid", this.updateProfileForm.value);
    }
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");
    console.log("203", firstInvalidControl);
    if (firstInvalidControl != null) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth",
      });
    } else {
      this.updateProfileForm.value["additionalMembers"].forEach((member, i) => {
        Object.keys(member).forEach((memberField: any) => {
          if (!member[memberField]) delete member[memberField];
        });
      });

      Object.keys(this.updateProfileForm.value).forEach((field: any) => {
        if (
          !this.updateProfileForm.value[field] ||
          this.updateProfileForm.value[field].length === 0
        )
          delete this.updateProfileForm.value[field];
      });

      this.updateProfileForm.value["arrImgIdProof"] = [];
      this.updateProfileForm.value["arrImgIncomeProof"] = [];
      this.updateProfileForm.value["arrImgBankStatements"] = [];
      this.updateProfileForm.value["arrImgMedicalProof"] = [];

      if (
        this.updateProfileForm.value.noOfYearsAtAdress &&
        this.updateProfileForm.value.noOfYearsAtAdress >= 0
      ) {
        let noofyears =
          this.updateProfileForm.value.noOfYearsAtAdress + " Years";
        this.updateProfileForm.value.noOfYearsAtAdress = noofyears;
      }

      if (
        this.updateProfileForm.value.noOfMonthsAtAdress &&
        this.updateProfileForm.value.noOfMonthsAtAdress >= 0
      ) {
        let noofmonths =
          this.updateProfileForm.value.noOfMonthsAtAdress + " Months";
        this.updateProfileForm.value.noOfMonthsAtAdress = noofmonths;
      }

      if (this.id_Proof_Ary && this.id_Proof_Ary.length > 0) {
        this.id_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgIdProof"].push(x);
        });
      }
      if (this.inc_Proof_Ary && this.inc_Proof_Ary.length > 0) {
        this.inc_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgIncomeProof"].push(x);
        });
      }
      if (this.bank_Proof_Ary && this.bank_Proof_Ary.length > 0) {
        this.bank_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgBankStatements"].push(x);
        });
      }

      if (this.medical_Proof_Ary && this.medical_Proof_Ary.length > 0) {
        this.medical_Proof_Ary.map((x) => {
          this.updateProfileForm.value["arrImgMedicalProof"].push(x);
        });
      }
      if (!this.assignedStaff["email"]) {
        if (
          !this.inc_Proof_Ary.length ||
          !this.bank_Proof_Ary.length ||
          !this.id_Proof_Ary.length
        ) {
          this.alertnotification.errorAlert(
            "Please upload your proof of ID, proof of income and bank statement"
          );
          return;
        }
      }

      //console.log(this.updateProfileForm.value);
      this.updateProfileForm.value["public"] = true;
      this.updateProfileForm.value["createdAt"] = new Date();
      this.updateProfileForm.value = {
        ...this.updateProfileForm.value,
        assignedStaff: { ...this.assignedStaff, assignedDate: new Date() },
      };
      console.log("1------>", this.updateProfileForm.value);
      this.firebaseservice
        .createPublicRecord(this.updateProfileForm.value)
        .then(async (records) => {
          console.log("successfully updated");
          this.alertnotification.successAlert(
            "Your form has been submitted successfully"
          );
          this.loaderService.hide();
          sessionStorage.removeItem(this.passcode);
          this.router.navigate([""]);
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
        });
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  removeItem(i) {
    this.updateProfileForm.get("additionalMembers").removeAt(i);
  }

  async uploadImages(event, i, fieldname) {
    console.log("upload Images eve", event);
    console.log("upload Images i", i);
    console.log("upload Images fieldname", fieldname);

    const blob = event.target.files[0];
    var childName;
    if (fieldname == "imgProfile") {
      childName = `images/profile_${new Date().getMilliseconds()}`;
      let ImageUrl = await this.commonUploadFile(blob, childName);
      this.updateProfileForm.value["imgProfile"] = ImageUrl;
      // this.onSubmit(false);
    } else if (fieldname == "imgIdProofMember") {
      childName = `images/id_member_${i + 2}_${new Date().getMilliseconds()}`;
      let ImageUrl = await this.commonUploadFile(blob, childName);
      this.updateProfileForm.value.additionalMembers[i][fieldname] = ImageUrl;
      // this.onSubmit(false);
    } else {
      childName = `images/income_member_${
        i + 2
      }_${new Date().getMilliseconds()}`;
      let ImageUrl = await this.commonUploadFile(blob, childName);
      this.updateProfileForm.value.additionalMembers[i][fieldname] = ImageUrl;
      // this.onSubmit(false);
    }
  }

  async uploadUserDocuments(event, i, fieldname) {
    //console.log(fieldname);
    var childName;
    if (event.target.files.length > 0) {
      for (var x = 0; x < event.target.files.length; x++) {
        const blob = event.target.files[x];

        if (fieldname == "arrImgIdProof") {
          childName = `images/id_${
            this.id_Proof_Ary.length + 1
          }_${new Date().getMilliseconds()}.${blob.name.split(".").pop()}`;
          let ImageUrl = await this.commonUploadFile(blob, childName);
          this.id_Proof_Ary.push(ImageUrl);
          this.updateProfileForm.value["arrImgIdProof"] = ImageUrl;
          //  this.onSubmit(false)
        } else if (fieldname == "arrImgIncomeProof") {
          childName = `images/income_${
            this.inc_Proof_Ary.length + 1
          }_${new Date().getMilliseconds()}.${blob.name.split(".").pop()}`;
          let ImageUrl = await this.commonUploadFile(blob, childName);
          this.inc_Proof_Ary.push(ImageUrl);
          this.updateProfileForm.value["arrImgIncomeProof"] = ImageUrl;
          // this.onSubmit(false)
        } else if (fieldname == "arrImgMedicalProof") {
          childName = `images/medical_${
            this.medical_Proof_Ary.length + 1
          }_${new Date().getMilliseconds()}`;
          let ImageUrl = await this.commonUploadFile(blob, childName);
          this.medical_Proof_Ary.push(ImageUrl);
          this.updateProfileForm.value["arrImgMedicalProof"] = ImageUrl;
          // this.onSubmit(false)
        } else {
          childName = `images/bank_${
            this.bank_Proof_Ary.length + 1
          }_${new Date().getMilliseconds()}.${blob.name.split(".").pop()}`;
          let ImageUrl = await this.commonUploadFile(blob, childName);
          this.bank_Proof_Ary.push(ImageUrl);
          this.updateProfileForm.value["arrImgBankStatements"] = ImageUrl;
          // this.onSubmit(false)
        }
      }
    } else {
      return;
    }
  }

  commonUploadFile(blob, childName) {
    return new Promise((resolve, reject) => {
      this.firebaseservice
        .uploadFile(blob, childName)
        .then((snapshot) => {
          snapshot.ref
            .getDownloadURL()
            .then((url) => {
              // console.log(url);
              this.loaderService.hide();
              resolve(url);
            })
            .catch((error) => {
              this.loaderService.hide();
              console.log(error);
              reject(false);
              // return false;
            });
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
          reject(false);
        });
    });
  }

  getUserDetails(id) {
    var records1 = [];
    return new Promise((resolve, reject) => {
      this.firebaseservice
        .getUserDetail(id)
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            var data = doc.data();
            data["id"] = doc.id;
            records1.push(data);
          });
          sessionStorage.setItem("userDetail", JSON.stringify(records1[0]));
          this.userDataService.changeuser(records1[0]);
          resolve(true);
          // this.loaderService.hide();
        })
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
            reject(false);
          }.bind(this)
        );
    });
  }

  dateConverter(date) {
    // console.log(date);
    return date && date.seconds ? moment.unix(date.seconds).toDate() : "";
  }

  check(eee) {
    console.log(eee);
  }

  checkUserMemberDetails(data, i, field) {
    if (field == "imgIncomeProofMember" || field == "imgIdProofMember") {
      return data && data.length > 0 ? data[i][field] : "";
    } else {
      return data && data.length > 0 ? data[i][field] : "";
    }
  }

  ngOnDestroy() {
    if (this.$userDataSubscription) {
      this.$userDataSubscription.unsubscribe();
    }
    if (sessionStorage.getItem(this.passcode)) {
      sessionStorage.removeItem(this.passcode);
    }
  }

  deleteImage(idx, fieldname) {
    Swal.fire({
      title: `Do you want to delete this ${
        fieldname == "imgIdProofMember" ? "Proof of ID" : "Proof of income"
      }?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value == true) {
        this.updateProfileForm.value.additionalMembers[idx][fieldname] = "";
        //  this.onSubmit(false);
      } else {
        return;
      }
    });
  }

  deleteUserDocuments(idx, fieldname) {
    Swal.fire({
      title: `Do you want to delete this ${
        fieldname == "arrImgIdProof"
          ? "Proof of ID"
          : fieldname == "arrImgIncomeProof"
          ? "Proof of income"
          : fieldname == "arrImgMedicalProof"
          ? "Proof of medical"
          : "Proof of Statements"
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value == true) {
        if (fieldname == "arrImgIdProof") {
          this.id_Proof_Ary.splice(idx, 1);
          //   this.onSubmit(false)
        } else if (fieldname == "arrImgIncomeProof") {
          this.inc_Proof_Ary.splice(idx, 1);
          // this.onSubmit(false)
        } else if (fieldname == "arrImgMedicalProof") {
          this.medical_Proof_Ary.splice(idx, 1);
          // this.onSubmit(false)
        } else {
          this.bank_Proof_Ary.splice(idx, 1);
          //this.onSubmit(false)
        }
      } else {
        return;
      }
    });
  }

  openImage(template1: TemplateRef<any>, url) {
    if ((url && url.includes("https")) || url.includes("http")) {
      this.viewImage = url;
      // return url
      this.modalRef = this.modalService.show(template1, {
        class: "modal-show-image",
      });
    }
  }

  decline(): void {
    this.modalRef.hide();
  }

  openFile() {
    window.open("assets/rightrent.pdf");
  }
  onPasswordSubmit() {
    this.apiservice.getRequest2(`getPasscode.php`).subscribe(
      async (response: any) => {
        this.passcode = response.data.password;
        // console.log(response.message);
        if (this.protectedPass.value.password == this.passcode) {
          sessionStorage.setItem(
            this.passcode,
            this.protectedPass.value.password
          );
          this.ngOnInit();
        } else {
          this.alertnotification.errorAlert(
            "Incorrect password. Please try again."
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onBsKeyPress(event) {
    if (event.keyCode != 8) {
      this.updateProfileForm.dateOfBirth = "";
      event.preventDefault();
      return;
    }
  }
}
