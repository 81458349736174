import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class SubscriberFunction {

    constructor() {}

    private searchCount = new BehaviorSubject(0);
    currentCount = this.searchCount.asObservable();

    updateSearchCount(data) {
        this.searchCount.next(data);
    }
}