import { Injectable } from '@angular/core';
// import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { HttpClient, HttpHeaders,HttpParams, HttpBackend  } from '@angular/common/http';
import { environment } from '../../../environments/environment'; 
import { Subject, BehaviorSubject } from 'rxjs';
// import { NgxSpinnerService } from 'ngx-spinner';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})

export class ApiServiceService {
 
  base_url = environment.baseUrl;
  base_url2 = environment.baseUrl2;
  root_url = environment.rootUrl;
  notification_url = environment.notificationUrl;
  private httpWithoutInterceptor: HttpClient;

  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      // withCredentials: true
  };

  constructor(private http: HttpClient,
    private httpBackend: HttpBackend
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  // @Cacheable({
  //   maxCacheCount: 20,
  //   maxAge: 90000,
  //   cacheBusterObserver: cacheBuster$
  // })
  getRequest(url : string, param = {}, root = false) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params : param,
      // withCredentials: true
    };
    return this.http.get(`${root?this.root_url:this.base_url}/`+url,httpOptions);
  }

  getRequest2(url : string, param = {}, root = false) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params : param,
      // withCredentials: true
    };
    return this.http.get(`${root?this.root_url:this.base_url2}/`+url,httpOptions);
  }

  _getRequest(url : string, param = {}, root = false) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params : param,
      // withCredentials: true
    };
    return this.httpWithoutInterceptor.get(`${root?this.root_url:this.base_url}/`+url,httpOptions);
  }

  // @Cacheable({
  //   maxCacheCount: 20,
  //   maxAge: 90000,
  //   cacheBusterObserver: cacheBuster$
  // })

  postRequest(url , request_data, origin = 'api') {
    return this.http.post(`${origin == 'api'? this.base_url : this.notification_url }/`+url, request_data,this.httpOptions);
  }

  postRequestPush(url, request_data) {
    return this.http.post(`${this.notification_url }/`+url, request_data,this.httpOptions);
  }

  postRequestToCore(url , request_data) {
    return this.http.post(`${this.root_url}/`+url, request_data,this.httpOptions);
  }

  // @CacheBuster({
  //   cacheBusterNotifier: cacheBuster$
  // })
  deleteRequest(url) {
    return this.http.delete(`${this.base_url}/`+url , this.httpOptions);
  }

  // @CacheBuster({
  //   cacheBusterNotifier: cacheBuster$
  // })
  uploadRequest(url , form_data) {
    return this.http.post(`${this.base_url}/`+url, form_data);
  }

  // @CacheBuster({
  //   cacheBusterNotifier: cacheBuster$
  // })
  putRequest(url , form_data) {
    return this.http.put(`${this.base_url}/`+url, form_data);
  }
}