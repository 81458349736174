<section class="property_detail_page">
    <div class="container">
        <button class="btn btn-green mb-3" (click)="backToPropertyList()">Back</button>
        <div class="row">
            <div class="col-md-6 col-sm-6 property_img">
                <div class="property_img_inner" (click) = "openImage(template1 ,propertyDetails.image)" style="cursor: pointer;">
                    <img *ngIf="propertyDetails.image" [src]="checkImageUrl(propertyDetails.image)">
                    <img *ngIf="!propertyDetails.image" src="assets/images/bg1.png">
                </div>
                <div class="map">
                    <iframe
                        [src] = "sanitizer.bypassSecurityTrustResourceUrl(msrc)" 
                        frameborder="0" 
                        style="border:0;" 
                        allowfullscreen
                        height="480px" 
                        width="450px"
                        ></iframe>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 property_text">
                <div class="property_text_inner">
                    <h3>{{propertyDetails.title}}</h3>
                    <h6>{{propertyDetails.roomtype ? propertyDetails.roomtype + ' Bedroom' : ''}}</h6>
                    <span>{{propertyDetails.property_address}}</span>
                    <div class="add_to_favortie"><img src="assets/images/heart.svg"
                            (click)="shortListOrFavorite(false)"> Add to Favorites</div>
                    <div class="txt">Register your interest here for this property</div>
                    <!-- <button class="btn btn-green" (click)="openModal(template)">Register Interest</button> -->
                    <button class="btn btn-green" (click)="addShortListed(true)">Register Interest</button>

                    <h5>Full Description</h5>
                    <p>{{propertyDetails.desc}}</p>

                    <h5>Location</h5>
                    <p>{{propertyDetails.property_address }}</p>

                    

                </div>
            </div>
        </div>

    </div>
</section>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Register Interest?</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Do you want to Shortlist this property?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-conf" (click)="shortListOrFavorite(true)">continue</button>
    </div>
</ng-template>

<ng-template #template1>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="dialog_img_inner">
            <img *ngIf="propertyDetails.image" [src]="viewImage" style="min-width: 500px;">
            <img *ngIf="!propertyDetails.image" src="assets/images/bg1.png" style="min-width: 500px;"s>
        </div>
    </div>
    
</ng-template>

