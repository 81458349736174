import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './layout/home/home.component';
import { CanActivateAdmin } from './core/guards/loginguard';
import { CanActivateLogin } from './core/guards/authguard';


const routes: Routes = [
  {
    path: "", component : HomeComponent , canActivate : [CanActivateLogin]
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then(mod => mod.AuthModule)
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then(mod => mod.UserModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { scrollPositionRestoration: 'disabled' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
