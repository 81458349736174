import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/core/services/api-service';
import { AlertNotification } from 'src/app/core/alertNotification/alertNotification';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notificationsArray = [];
  userDetails = null;

  constructor(private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
    this.fetchNotificationData()
  }



  fetchNotificationData(){
    this.apiservice.postRequest(`getpushnotification`, {email: this.userDetails.email}, 'notification').subscribe((response: any) => {
      let notiArr = response.data;
      notiArr.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      console.log(notiArr, 'notiArr')
      this.notificationsArray = notiArr;
    },
      error => {
        // console.log(error.error);
        this.alertnotification.errorAlert(error.error.message);
      });
  }

}
