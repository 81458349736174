<app-header></app-header>
<section class="banner bg">
    <h2>Find Your <strong>Permanent Accommodation</strong></h2>
    <div class="app_btn">
        <a href="#" class="mr-2"><img src="assets/images/btn-appstore.svg"></a>
        <a href="#"><img src="assets/images/btn-googleplay.svg"></a>
    </div>
</section>
<div class="heading">My Social Housing</div>
<section class="service_box">
    <div class="container">
        <div class="row mb-5">
            <div class="col-md-6 col-sm-6 service_img">
                <img class="w-100" src="assets/images/social_house1.png">
            </div>
            <div class="col-md-6 col-sm-6 service_text">
                <div class="service_txt_inner">
                    <h2><img src="assets/images/filter.svg"> Homes available Now</h2>
                    <a routerLink="/login" class="btn btn-green">Click Here</a>
                </div>
            </div>
        </div>
        <div class="row flex-row-reverse  mb-5">
            <div class="col-md-6 col-sm-6 service_img">
                <img src="assets/images/bhs.png">
            </div>
            <div class="col-md-6 col-sm-6 service_text">
                <div class="service_txt_inner">
                    <h2><img src="assets/images/userbhs.svg"> My Social Housing Profile</h2>
                    <a routerLink="/login" class="btn btn-green">Click Here</a>
                </div>
            </div>
        </div>
        <div class="row  mb-5">
            <div class="col-md-6 col-sm-6 service_img">
                <img class="w-100" src="assets/images/social_house2.png">
            </div>
            <div class="col-md-6 col-sm-6 service_text">
                <div class="service_txt_inner">
                    <h2><img src="assets/images/gift-list.svg"> Property Shortlist</h2>
                    <a routerLink="/login" class="btn btn-green btn_prop">Click Here</a>
                </div>
            </div>
        </div>
        <div class="row flex-row-reverse  ">
            <div class="col-md-6 col-sm-6 service_img">
                <img src="assets/images/notification.png">
            </div>
            <div class="col-md-6 col-sm-6 service_text">
                <div class="service_txt_inner">
                    <h2><img src="assets/images/alarm.svg"> Notifications</h2>
                    <a routerLink="/login" class="btn btn-green">Click Here</a>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>