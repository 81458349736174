import { FormGroup } from "@angular/forms";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

/**
 * @purpose custom validator to check that two fields match that must be same
 * @method MustMatch
 * @param control
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function MustNotIncludeEmail(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    let email = matchingControl.value?.split('@')[0];
    if (control.value.includes(email)) {
      matchingControl.setErrors({ mustNotIncludeEmail: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

/**
 * @purpose to render error message for password checks like required contain spacial character upper case etc
 * @method PasswordStrengthValidator
 * @param control
 */
export const PasswordStrengthValidator = function(
  control: AbstractControl
): ValidationErrors | null {
  let value: string = control.value || "";
  let validationFalse = false;
  var errors = "";
  // if (!value) {
  //   validationFalse = true;
  //   errors += `<li>  Password should not be blank</li>`;
  // }

  // let upperCaseCharacters = /[A-Z]+/g;
  // if (upperCaseCharacters.test(value) === false) {
  //   validationFalse = true;
  //   errors += `<li>  Password should contain upper case character</li>`;
  // }

  // let numberCharacters = /[0-9]+/g;
  // if (numberCharacters.test(value) === false) {
  //   errors += `<li>  Password should contain number </li>`;
  // }

  // let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  // if (specialCharacters.test(value) === false) {
  //   validationFalse = true;
  //   errors += `<li>  Password should contain  special character </li>`;
  // }

  // if (value.length < 8) {
  //   validationFalse = true;
  //   errors += `<li>  Password should be 8 characters long </li>`;
  // }

  if (value.includes('email.com')) {
    validationFalse = true;
    errors += `Enter valid email`;
  }

  if (validationFalse) {
    errors += "</span>";
    return { passwordStrength: errors };
  }

  return null;
};
