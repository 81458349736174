<app-tabs-headers [properties]="0" [listName]="'profile'"></app-tabs-headers>
<section class="profile_sec">
  <div class="container">
    <div class="profile_box">
      <div class="profile_img">
        <ng-container *ngIf="!userDetails.imgProfile">
          <img src="assets/images/user.png" />
        </ng-container>
        <ng-container *ngIf="userDetails.imgProfile">
          <img
            [src]="userDetails.imgProfile"
            (click)="openImage(template1, userDetails.imgProfile)"
          />
        </ng-container>
        <div class="edit_sec">
          <img src="assets/images/edit.svg" (click)="file.click()" />
          <input
            #file
            (change)="uploadImages($event, null, 'imgProfile')"
            type="file"
            accept="image/*"
          />
        </div>
      </div>
      <h4>Hi {{ userDetails.firstName }} {{ userDetails.lastName }}</h4>
    </div>
  </div>
</section>
<section class="form_box">
  <div class="container">
    <div class="form_inner">
      <form [formGroup]="updateProfileForm" (ngSubmit)="onSubmit(true)">
        <div class="row">
          <h3>Account Details</h3>
          <div class="col-sm-6 wh_input">
            <label>First Name <span class="danger">*</span> </label>
            <input
              [(ngModel)]="userDetails.firstName"
              type="text"
              formControlName="firstName"
              class="form-control"
              placeholder="First Name"
             
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.firstName.dirty ||
                  updateProfileForm.controls.firstName.touched) &&
                updateProfileForm.controls.firstName.errors
              "
            >
              <small
                *ngIf="updateProfileForm.controls.firstName.errors.required"
                >First Name is required</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Last Name <span class="danger">*</span></label>
            <input
              [(ngModel)]="userDetails.lastName"
              type="text"
              formControlName="lastName"
              class="form-control"
              placeholder="Last Name"
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.lastName.dirty ||
                  updateProfileForm.controls.lastName.touched) &&
                updateProfileForm.controls.lastName.errors
              "
            >
              <small *ngIf="updateProfileForm.controls.lastName.errors.required"
                >Last Name is required</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Email</label>
            <input
              [(ngModel)]="userDetails.email"
              type="email"
              formControlName="email"
              class="form-control"
              placeholder="email"
              readonly
            />
          </div>
          <div class="col-sm-12 wh_input">
            <label>Address <span class="danger">*</span></label>
            <textarea
              [(ngModel)]="userDetails.address"
              class="form-control"
              formControlName="address"
              placeholder="Address"
            ></textarea>
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.address.dirty ||
                  updateProfileForm.controls.address.touched) &&
                updateProfileForm.controls.address.errors
              "
            >
              <small *ngIf="updateProfileForm.controls.address.errors.required"
                >Address is required</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Postcode <span class="danger">*</span></label>
            <input
              [(ngModel)]="userDetails.postCode"
              type="text"
              class="form-control"
              formControlName="postCode"
              placeholder="Postcode"
            />
            <!-- {{updateProfileForm?.controls?.postCode?.errors | json}} -->
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.postCode.dirty ||
                  updateProfileForm.controls.postCode.touched) &&
                updateProfileForm.controls.postCode.errors
              "
            >
              <div
                *ngIf="
                  updateProfileForm.controls.postCode.errors.postalCodeValidator
                "
                [innerHTML]="
                  updateProfileForm.controls.postCode.errors.postalCodeValidator
                "
              ></div>
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Date of Birth <span class="danger">*</span></label>
            <input
              [maxDate]="currentDate"
              [bsValue]="dateConverter(userDetails.dateOfBirth)"
              formControlName="dateOfBirth"
              id="birthday"
              class="form-control"
              placeholder="Date of birth"
              name="birthday"
              bsDatepicker
              [bsConfig]="{
                containerClass: 'theme-blue',
                showWeekNumbers: false,
                dateInputFormat: 'DD/MM/YYYY',
                rangeInputFormat: 'DD/MM/YYYY'
              }"
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.dateOfBirth.dirty ||
                  updateProfileForm.controls.dateOfBirth.touched) &&
                updateProfileForm.controls.dateOfBirth.errors
              "
            >
              <small
                *ngIf="updateProfileForm.controls.dateOfBirth.errors.required"
                >DOB is required</small
              >
            </div>
          </div>
          <div
            class="col-sm-6 wh_input"
            *ngIf="referenceAgents && referenceAgents.length"
          >
            <label>Referring Agent <span class="danger">*</span></label>
            <input
              [(ngModel)]="userDetails.referringAgent"
              type="text"
              class="form-control"
              formControlName="referringAgent"
              placeholder="Referring Agent"
              [typeahead]="referenceAgents"
              typeaheadOptionField="name"
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.referringAgent.dirty ||
                  updateProfileForm.controls.referringAgent.touched) &&
                updateProfileForm.controls.referringAgent.errors
              "
            >
              <small
                *ngIf="
                  updateProfileForm.controls.referringAgent.errors.required
                "
                >Referring Agent is required</small
              >
            </div>
          </div>

          <div class="spacer"></div>
          <h3>Landlord Details</h3>
          <div class="col-sm-6 wh_input">
            <label>Landlord Name <span class="danger">*</span></label>
            <input
              [(ngModel)]="userDetails.landlordName"
              type="text"
              class="form-control"
              formControlName="landlordName"
              placeholder="Landlord name"
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.landlordName.dirty ||
                  updateProfileForm.controls.landlordName.touched) &&
                updateProfileForm.controls.landlordName.errors
              "
            >
              <small
                *ngIf="updateProfileForm.controls.landlordName.errors.required"
                >Landlord name is required</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Landlord phone number <span class="danger">*</span></label>
            <input
              [(ngModel)]="userDetails.landlordPhoneNumber"
              type="text"
              class="form-control"
              formControlName="landlordPhoneNumber"
              placeholder="Phone number"
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.landlordPhoneNumber.dirty ||
                  updateProfileForm.controls.landlordPhoneNumber.touched) &&
                updateProfileForm.controls.landlordPhoneNumber.errors
              "
            >
              <small
                *ngIf="
                  updateProfileForm.controls.landlordPhoneNumber.errors.required
                "
                >Landlord Phone number is required</small
              >
              <small
                *ngIf="
                  updateProfileForm.controls.landlordPhoneNumber.errors.pattern
                "
                >Enter valid Phone Number</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Landlord Email</label>
            <input
              [(ngModel)]="userDetails.landlordEmail"
              type="text"
              class="form-control"
              formControlName="landlordEmail"
              placeholder="Landlord email"
            />
          </div>

          <div class="col-sm-6 wh_input">
            <label>Bedroom Size Required <span class="danger">*</span></label>
            <ng-select
              [searchable]="false"
              [(ngModel)]="userDetails.bedroomSize"
              class="form-control"
              [items]="bedroomSize"
              formControlName="bedroomSize"
              placeholder="Select Bedsize"
            >
            </ng-select>
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.bedroomSize.dirty ||
                  updateProfileForm.controls.bedroomSize.touched) &&
                updateProfileForm.controls.bedroomSize.errors
              "
            >
              <small
                *ngIf="updateProfileForm.controls.bedroomSize.errors.required"
                >Bedroom size is required</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Current Accommodation <span class="danger">*</span></label>
            <ng-select
              [searchable]="false"
              [(ngModel)]="userDetails.currentAccommodation"
              class="form-control"
              [items]="currentAccommodation"
              formControlName="currentAccommodation"
              placeholder="Select current accomodation"
            >
            </ng-select>
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.currentAccommodation.dirty ||
                  updateProfileForm.controls.currentAccommodation.touched) &&
                updateProfileForm.controls.currentAccommodation.errors
              "
            >
              <small
                *ngIf="
                  updateProfileForm.controls.currentAccommodation.errors
                    .required
                "
                >Current Accomodation is required</small
              >
            </div>
          </div>
          <div class="spacer"></div>
          <div class="col-sm-4 wh_radio_btn">
            <label>Do you have rent arrears?</label>
            <div class="custom-control custom-radio btn-radio">
              <input
                [(ngModel)]="userDetails.isRentArrearsProvided"
                type="radio"
                id="rentArrear1"
                class="custom-control-input"
                [value]="true"
                formControlName="isRentArrearsProvided"
              />
              <label for="rentArrear1" class="custom-control-label">Yes</label>
            </div>
            <div class="custom-control custom-radio btn-radio">
              <input
                [(ngModel)]="userDetails.isRentArrearsProvided"
                type="radio"
                id="rentArrear2"
                class="custom-control-input"
                [value]="false"
                formControlName="isRentArrearsProvided"
              />
              <label for="rentArrear2" class="custom-control-label">No</label>
            </div>
          </div>
          <div class="col-sm-4 wh_radio_btn">
            <label>Medical</label>
            <div class="custom-control custom-radio btn-radio">
              <input
                [(ngModel)]="userDetails.isMedicalDetailsProvided"
                type="radio"
                id="medical1"
                class="custom-control-input"
                [value]="true"
                formControlName="isMedicalDetailsProvided"
              />
              <label for="medical1" class="custom-control-label">Yes</label>
            </div>
            <div class="custom-control custom-radio btn-radio">
              <input
                [(ngModel)]="userDetails.isMedicalDetailsProvided"
                type="radio"
                id="medical2"
                class="custom-control-input"
                [value]="false"
                formControlName="isMedicalDetailsProvided"
              />
              <label for="medical2" class="custom-control-label">No</label>
            </div>
          </div>
          <div class="col-sm-4 wh_radio_btn">
            <label>ASB</label>
            <div class="custom-control custom-radio btn-radio">
              <input
                [(ngModel)]="userDetails.isASBDetailsProvided"
                type="radio"
                id="ABS1"
                class="custom-control-input"
                [value]="true"
                formControlName="isASBDetailsProvided"
              />
              <label for="ABS1" class="custom-control-label">Yes</label>
            </div>
            <div class="custom-control custom-radio btn-radio">
              <input
                [(ngModel)]="userDetails.isASBDetailsProvided"
                type="radio"
                id="ABS2"
                class="custom-control-input"
                [value]="false"
                formControlName="isASBDetailsProvided"
              />
              <label for="ABS2" class="custom-control-label">No</label>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="row">
              <div
                *ngIf="updateProfileForm.controls.isRentArrearsProvided.value"
                class="col-sm-6 wh_input"
              >
                <label>Rent Arrear Amount</label>
                <!-- <input type="text" class="form-control" placeholder="Rent arrear amount" formControlName="rentArrearsAmount"> -->
                <textarea
                  [(ngModel)]="userDetails.rentArrearsAmount"
                  class="form-control"
                  formControlName="rentArrearsAmount"
                  placeholder="Rent arrear amount"
                ></textarea>
                <div
                  class="has-error"
                  *ngIf="
                    (updateProfileForm.controls.rentArrearsAmount.dirty ||
                      updateProfileForm.controls.rentArrearsAmount.touched) &&
                    updateProfileForm.controls.rentArrearsAmount.errors
                  "
                >
                  <small
                    *ngIf="
                      updateProfileForm.controls.rentArrearsAmount.errors
                        .pattern
                    "
                    >Enter valid Amount</small
                  >
                </div>
              </div>

              <div
                *ngIf="
                  updateProfileForm.controls.isMedicalDetailsProvided.value
                "
                class="col-sm-6 wh_input"
              >
                <label>Medical Details</label>
                <!-- <input type="text" class="form-control" placeholder="Medical Details" formControlName="medicalDetails"> -->
                <textarea
                  [(ngModel)]="userDetails.medicalDetails"
                  class="form-control"
                  formControlName="medicalDetails"
                  placeholder="Medical Details"
                ></textarea>
              </div>

              <div
                *ngIf="updateProfileForm.controls.isASBDetailsProvided.value"
                class="col-sm-6 wh_input"
              >
                <label>ASB Details</label>
                <!-- <input type="text" class="form-control" placeholder="ASB details" formControlName="asbDetails"> -->
                <textarea
                  [(ngModel)]="userDetails.asbDetails"
                  class="form-control"
                  formControlName="asbDetails"
                  placeholder="ASB details"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="col-sm-6 wh_input">
            <label>Email Address 1 <span class="danger">*</span></label>
            <input
              [(ngModel)]="userDetails.email1"
              type="email"
              class="form-control"
              placeholder="Email 1"
              formControlName="email1"
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.email1.dirty ||
                  updateProfileForm.controls.email1.touched) &&
                updateProfileForm.controls.email1.errors
              "
            >
              <small *ngIf="updateProfileForm.controls.email1.errors.required"
                >Email is required</small
              >
              <small *ngIf="updateProfileForm.controls.email1.errors.pattern"
                >Enter valid Email</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Email Address 2</label>
            <input
              [(ngModel)]="userDetails.email2"
              type="email"
              class="form-control"
              placeholder="Email 2"
              formControlName="email2"
            />
          </div>
          <div class="col-sm-6 wh_input">
            <label>Phone Number <span class="danger">*</span></label>
            <input
              [(ngModel)]="userDetails.phoneNumber1"
              type="text"
              class="form-control"
              placeholder="Phone number"
              formControlName="phoneNumber1"
            />
            <div
              class="has-error"
              *ngIf="
                (updateProfileForm.controls.phoneNumber1.dirty ||
                  updateProfileForm.controls.phoneNumber1.touched) &&
                updateProfileForm.controls.phoneNumber1.errors
              "
            >
              <small
                *ngIf="updateProfileForm.controls.phoneNumber1.errors.required"
                >Phone number is required</small
              >
              <small
                *ngIf="updateProfileForm.controls.phoneNumber1.errors.pattern"
                >Enter valid Phone Number</small
              >
            </div>
          </div>
          <div class="col-sm-6 wh_input">
            <label>Phone Number 2</label>
            <input
              [(ngModel)]="userDetails.phoneNumber2"
              type="text"
              class="form-control"
              placeholder="Phone number 2"
              formControlName="phoneNumber2"
            />
          </div>

          <div class="spacer"></div>
          <h3 class="mb-2">My Documents</h3>
          <span class="tag_line"
            >You can upload multiple proof of ID, proof of income and bank
            statement</span
          >
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-4 document_box">
                <div class="document_box_inner">
                  <h5>ID*</h5>
                  <div class="upload_btn">
                    <!-- <button class="btn btn-bordered">Upload Proof of ID</button> -->
                    <label for="proof-upload" class="btn btn-bordered">
                      Upload Proof of ID
                    </label>
                    <input
                      id="proof-upload"
                      (change)="uploadUserDocuments($event, 0, 'arrImgIdProof')"
                      type="file"
                      accept="image/*, .pdf , .zip , .xlsx, .xls, .csv , .doc, .docx"
                    />
                    <a
                      class="info-button"
                      target="_blank"
                      href="https://www.mysocialhousing.co.uk/app-proof-of-id"
                    >
                      <img src="assets/images/information.png" />
                      <span>Find out what we can accept as proof of id</span>
                    </a>
                  </div>
                  <!-- <div *ngIf="updateProfileForm.controls.arrImgIdProof.length > 0"> -->
                  <div *ngIf="id_Proof_Ary.length > 0">
                    <div
                      class="upload_imgsec"
                      *ngFor="let item of id_Proof_Ary; let i = index"
                    >
                      <div class="upload_img_loop">
                        <div class="img-up img-con">
                          <img
                            [src]="item"
                            (click)="openImage(template1, item)"
                          />
                          <div class="del-Icon">
                            <span
                              (click)="deleteUserDocuments(i, 'arrImgIdProof')"
                              >X</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 document_box">
                <div class="document_box_inner">
                  <h5>Income</h5>
                  <div class="upload_btn">
                    <!-- <button class="btn btn-bordered">Upload Proof of Income</button> -->
                    <label for="income-upload" class="btn btn-bordered">
                      Upload Proof of Income*
                    </label>
                    <input
                      id="income-upload"
                      (change)="
                        uploadUserDocuments($event, 0, 'arrImgIncomeProof')
                      "
                      type="file"
                      accept="image/*, .pdf , .zip , .xlsx, .xls, .csv , .doc, .docx"
                    />
                    <a
                      class="info-button"
                      target="_blank"
                      href="https://www.mysocialhousing.co.uk/app-proof-of-income"
                    >
                      <img src="assets/images/information.png" />
                      <span
                        >Find out what we can accept as proof of income</span
                      >
                    </a>
                  </div>
                  <div *ngIf="inc_Proof_Ary.length > 0">
                    <div
                      class="upload_imgsec"
                      *ngFor="let item of inc_Proof_Ary; let i = index"
                    >
                      <div class="upload_img_loop">
                        <div class="img-up img-con">
                          <img
                            [src]="item"
                            (click)="openImage(template1, item)"
                          />
                          <div class="del-Icon">
                            <span
                              (click)="
                                deleteUserDocuments(i, 'arrImgIncomeProof')
                              "
                              >X</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 document_box">
                <div class="document_box_inner">
                  <h5>Statements</h5>
                  <div class="upload_btn">
                    <!-- <button class="btn btn-bordered">Upload Proof of Statements</button> -->
                    <label for="statement-upload" class="btn btn-bordered">
                      Upload Proof of Statements*
                    </label>
                    <input
                      id="statement-upload"
                      (change)="
                        uploadUserDocuments($event, 0, 'arrImgBankStatements')
                      "
                      type="file"
                      accept="image/*, .pdf , .zip , .xlsx, .xls, .csv , .doc, .docx"
                    />
                  </div>
                  <div *ngIf="bank_Proof_Ary.length > 0">
                    <div
                      class="upload_imgsec"
                      *ngFor="let item of bank_Proof_Ary; let i = index"
                    >
                      <div class="upload_img_loop">
                        <div class="img-up img-con">
                          <img
                            [src]="item"
                            (click)="openImage(template1, item)"
                          />
                          <div class="del-Icon">
                            <span
                              (click)="
                                deleteUserDocuments(i, 'arrImgBankStatements')
                              "
                              >X</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="spacer"></div>
          <h3>Family Composition</h3>
          <div class="col-sm-6 wh_input">
            <label>First Name</label>
            <input
              [(ngModel)]="userDetails.firstNameFC"
              type="text"
              class="form-control"
              formControlName="firstNameFC"
              placeholder="First name"
            />
          </div>
          <div class="col-sm-6 wh_input">
            <label>Last Name</label>
            <input
              [(ngModel)]="userDetails.lastNameFC"
              type="text"
              class="form-control"
              formControlName="lastNameFC"
              placeholder="Last name"
            />
          </div>
          <div class="col-sm-6 wh_input">
            <label>Date of Birth</label>
            <input
              [maxDate]="currentDate"
              [bsValue]="dateConverter(userDetails.dateOfBirthFC)"
              id="birthday"
              formControlName="dateOfBirthFC"
              class="form-control"
              placeholder="Date of birth"
              name="birthday"
              bsDatepicker
              [bsConfig]="{
                containerClass: 'theme-blue',
                showWeekNumbers: false,
                rangeInputFormat: 'DD/MM/YYYY'
              }"
            />
          </div>
          <div class="col-sm-6 wh_input">
            <label>Relationship to you</label>
            <ng-select
              [searchable]="false"
              [(ngModel)]="userDetails.relationshipToYouFC"
              class="form-control"
              [items]="relationshipOption"
              formControlName="relationshipToYouFC"
              placeholder="Select relationship"
            >
            </ng-select>
          </div>
          <h3 class="color_green">Additional Family Member</h3>
          <!-- <div class="col-sm-6 wh_input">
                        <h5>
                            <span style="cursor: pointer;" class="btn btn-bordered"> Add Family Member 
                            </span>
                            <button type="button" *ngIf="showFamilyDetails && (updateProfileForm.get('additionalMembers').length < 3)" class="ml-2 addBtn" (click)="addItem()"> +</button> 
                        </h5>
                    </div> -->
        </div>
        <div>
          <div
            formArrayName="additionalMembers"
            *ngFor="
              let item of updateProfileForm.get('additionalMembers')[
                'controls'
              ];
              let i = index
            "
          >
            <div class="row" [formGroupName]="i">
              <div class="col-sm-12">
                <ng-container [ngSwitch]="i">
                  <h5 *ngSwitchCase="0">Second</h5>
                  <h5 *ngSwitchCase="1">Third</h5>
                  <h5 *ngSwitchCase="2">Fourth</h5>
                  <h5 *ngSwitchCase="3">Fifth</h5>
                  <h5 *ngSwitchCase="4">Sixth</h5>
                  <h5 *ngSwitchDefault>-</h5>
                </ng-container>
              </div>
              <div class="col-sm-6 wh_input">
                <label>First Name</label>
                <input
                  [ngModel]="
                    checkUserMemberDetails(
                      userDetails.additionalMembers,
                      i,
                      'firstName'
                    )
                  "
                  type="text"
                  formControlName="firstName"
                  class="form-control"
                  placeholder="First name"
                />
                <div></div>
              </div>
              <div class="col-sm-6 wh_input">
                <label>Last Name</label>
                <input
                  [ngModel]="
                    checkUserMemberDetails(
                      userDetails.additionalMembers,
                      i,
                      'lastName'
                    )
                  "
                  type="text"
                  formControlName="lastName"
                  class="form-control"
                  placeholder="Last name"
                />
              </div>
              <div class="col-sm-6 wh_input">
                <label>Date of Birth</label>
                <div>
                  <input
                    [maxDate]="currentDate"
                    [ngModel]="
                      checkUserMemberDetails(
                        userDetails.additionalMembers,
                        i,
                        'dateOfBirth'
                      )
                    "
                    bsDatepicker
                    [bsConfig]="{
                      containerClass: 'theme-blue',
                      showWeekNumbers: false,
                      rangeInputFormat: 'DD/MM/YYYY',
                      dateInputFormat: 'DD/MM/YYYY'
                    }"
                    formControlName="dateOfBirth"
                    [id]="'birthday' + i"
                    class="form-control"
                    placeholder="Date of birth"
                    name="birthday"
                  />
                  <!-- <input   formControlName="dateOfBirth" [id]="'birthday'+i" class="form-control" class="form-control" placeholder="Date of birth" name="birthday" bsDatepicker [bsConfig]="{containerClass: 'theme-blue' , showWeekNumbers: false , dateInputFormat: 'DD/MM/YYYY', rangeInputFormat: 'DD/MM/YYYY'}"> -->
                </div>
              </div>
              <div class="col-sm-6 wh_input">
                <label>Relationship to you</label>
                <ng-select
                  [searchable]="false"
                  [ngModel]="
                    checkUserMemberDetails(
                      userDetails.additionalMembers,
                      i,
                      'relationshipToYou'
                    )
                  "
                  class="form-control"
                  [items]="relationshipOption"
                  formControlName="relationshipToYou"
                  placeholder="Select relationship"
                >
                </ng-select>
              </div>
              <div class="col-sm-6 wh_input">
                <div class="alignImage">
                  <div class="familyDoc">
                    <img
                      [src]="
                        checkUserMemberDetails(
                          userDetails.additionalMembers,
                          i,
                          'imgIdProofMember'
                        )
                          ? checkUserMemberDetails(
                              userDetails.additionalMembers,
                              i,
                              'imgIdProofMember'
                            )
                          : defaultImage
                      "
                      (click)="
                        openImage(
                          template1,
                          checkUserMemberDetails(
                            userDetails.additionalMembers,
                            i,
                            'imgIdProofMember'
                          )
                        )
                      "
                    />
                  </div>
                  <div
                    class="delteImg"
                    *ngIf="
                      checkUserMemberDetails(
                        userDetails.additionalMembers,
                        i,
                        'imgIdProofMember'
                      )
                    "
                  >
                    <img
                      src="assets/images/delete.png"
                      (click)="deleteImage(i, 'imgIdProofMember')"
                    />
                  </div>
                </div>
                <!-- <button class="btn btn-green">Upload Proof of ID</button> -->
                <div>
                  <label [for]="i + 'id'" class="btn btn-green">
                    Upload Proof of ID
                  </label>
                  <input
                    [id]="i + 'id'"
                    (change)="uploadImages($event, i, 'imgIdProofMember')"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
              <div class="col-sm-6 wh_input">
                <!-- <button class="btn btn-green">Upload Proof of income</button> -->
                <div class="alignImage">
                  <div class="familyDoc">
                    <img
                      [src]="
                        checkUserMemberDetails(
                          userDetails.additionalMembers,
                          i,
                          'imgIncomeProofMember'
                        )
                          ? checkUserMemberDetails(
                              userDetails.additionalMembers,
                              i,
                              'imgIncomeProofMember'
                            )
                          : defaultImage
                      "
                      (click)="
                        openImage(
                          template1,
                          checkUserMemberDetails(
                            userDetails.additionalMembers,
                            i,
                            'imgIncomeProofMember'
                          )
                        )
                      "
                    />
                  </div>
                  <div
                    class="delteImg"
                    *ngIf="
                      checkUserMemberDetails(
                        userDetails.additionalMembers,
                        i,
                        'imgIncomeProofMember'
                      )
                    "
                  >
                    <img
                      src="assets/images/delete.png"
                      (click)="deleteImage(i, 'imgIncomeProofMember')"
                    />
                  </div>
                </div>
                <div>
                  <label [for]="i + 'income'" class="btn btn-green">
                    Upload Proof of income
                  </label>
                  <input
                    [id]="i + 'income'"
                    (change)="uploadImages($event, i, 'imgIncomeProofMember')"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- ------ -->
          <div class="spacer"></div>
          <!-- <div class="col-sm-6 wh_input">
                        <label>Bedroom Size Required <span class="danger">*</span></label>
                        <ng-select [searchable]=false  [(ngModel)]="userDetails.bedroomSize" class="form-control" [items]="bedroomSize" formControlName="bedroomSize" placeholder="Select Bedsize"> </ng-select>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.bedroomSize.dirty || updateProfileForm.controls.bedroomSize.touched) && updateProfileForm.controls.bedroomSize.errors">
                            <small *ngIf="updateProfileForm.controls.bedroomSize.errors.required">Bedroom size is required</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Current Accommodation <span class="danger">*</span></label>
                        <ng-select [searchable]=false  [(ngModel)]="userDetails.currentAccommodation" class="form-control" [items]="currentAccommodation" formControlName="currentAccommodation" placeholder="Select current accomodation"> </ng-select>
                        <div class="has-error" *ngIf="(updateProfileForm.controls.currentAccommodation.dirty || updateProfileForm.controls.currentAccommodation.touched) && updateProfileForm.controls.currentAccommodation.errors">
                            <small *ngIf="updateProfileForm.controls.currentAccommodation.errors.required">Current Accomodation is required</small>
                        </div>
                    </div> -->
          <!-- <div class="col-sm-4 wh_radio_btn">
                        <label>Do you have rent arrears?</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isRentArrearsProvided" type="radio" id="customRadio1" class="custom-control-input" [value]="true" formControlName="isRentArrearsProvided">
                            <label for="customRadio2" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isRentArrearsProvided" type="radio" id="customRadio2" class="custom-control-input" [value]="false" formControlName="isRentArrearsProvided">
                            <label for="customRadio1" class="custom-control-label">No</label>
                        </div>
                    </div> -->
          <!-- <div class="col-sm-4 wh_radio_btn">
                        <label>Do you have rent arrears?</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isRentArrearsProvided" type="radio" id="rentArrear1" class="custom-control-input" [value]="true" formControlName="isRentArrearsProvided">
                            <label for="rentArrear1" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isRentArrearsProvided" type="radio" id="rentArrear2" class="custom-control-input" [value]="false" formControlName="isRentArrearsProvided">
                            <label for="rentArrear2" class="custom-control-label">No</label>
                        </div>
                    </div>
                    <div class="col-sm-4 wh_radio_btn">
                        <label>Medical</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isMedicalDetailsProvided" type="radio" id="medical1" class="custom-control-input" [value]="true" formControlName="isMedicalDetailsProvided">
                            <label for="medical1" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isMedicalDetailsProvided" type="radio" id="medical2" class="custom-control-input" [value]="false" formControlName="isMedicalDetailsProvided">
                            <label for="medical2" class="custom-control-label">No</label>
                        </div>
                    </div>
                    <div class="col-sm-4 wh_radio_btn">
                        <label>ASB</label>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isASBDetailsProvided" type="radio" id="ABS1" class="custom-control-input" [value]="true" formControlName="isASBDetailsProvided">
                            <label for="ABS1" class="custom-control-label">Yes</label>
                        </div>
                        <div class="custom-control custom-radio btn-radio">
                            <input [(ngModel)]="userDetails.isASBDetailsProvided" type="radio" id="ABS2" class="custom-control-input" [value]="false" formControlName="isASBDetailsProvided">
                            <label for="ABS2" class="custom-control-label">No</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div *ngIf="updateProfileForm.controls.isRentArrearsProvided.value" class="col-sm-6 wh_input">
                                <label>Rent Arrear Amount</label>
                                
                                <textarea [(ngModel)]="userDetails.rentArrearsAmount" class="form-control" formControlName="rentArrearsAmount" placeholder="Rent arrear amount"></textarea>
                                <div class="has-error" *ngIf="(updateProfileForm.controls.rentArrearsAmount.dirty || updateProfileForm.controls.rentArrearsAmount.touched) && updateProfileForm.controls.rentArrearsAmount.errors">
                                    <small *ngIf="updateProfileForm.controls.rentArrearsAmount.errors.pattern">Enter valid Amount</small>
                                </div>
                            </div>

                            <div *ngIf="updateProfileForm.controls.isMedicalDetailsProvided.value" class="col-sm-6 wh_input">
                                <label>Medical Details</label>
                                
                                <textarea [(ngModel)]="userDetails.medicalDetails" class="form-control" formControlName="medicalDetails" placeholder="Medical Details"></textarea>
                            </div>

                            <div *ngIf="updateProfileForm.controls.isASBDetailsProvided.value" class="col-sm-6 wh_input">
                                <label>ASB Details</label>
                                
                                <textarea [(ngModel)]="userDetails.asbDetails" class="form-control" formControlName="asbDetails" placeholder="ASB details"></textarea>
                            </div>

                        </div>
                    </div>

                    <div class="col-sm-6 wh_input">
                        <label>Email Address 1 <span class="danger">*</span></label>
                        <input [(ngModel)]="userDetails.email1" type="email" class="form-control" placeholder="Email 1" formControlName="email1">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.email1.dirty || updateProfileForm.controls.email1.touched) && updateProfileForm.controls.email1.errors">
                            <small *ngIf="updateProfileForm.controls.email1.errors.required">Email is required</small>
                            <small *ngIf="updateProfileForm.controls.email1.errors.pattern">Enter valid Email</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Email Address 2</label>
                        <input [(ngModel)]="userDetails.email2" type="email" class="form-control" placeholder="Email 2" formControlName="email2">
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Phone Number <span class="danger">*</span></label>
                        <input [(ngModel)]="userDetails.phoneNumber1" type="text" class="form-control" placeholder="Phone number" formControlName="phoneNumber1">
                        <div class="has-error" *ngIf="(updateProfileForm.controls.phoneNumber1.dirty || updateProfileForm.controls.phoneNumber1.touched) && updateProfileForm.controls.phoneNumber1.errors">
                            <small *ngIf="updateProfileForm.controls.phoneNumber1.errors.required">Phone number is required</small>
                            <small *ngIf="updateProfileForm.controls.phoneNumber1.errors.pattern">Enter valid Phone Number</small>
                        </div>
                    </div>
                    <div class="col-sm-6 wh_input">
                        <label>Phone Number 2</label>
                        <input [(ngModel)]="userDetails.phoneNumber2" type="text" class="form-control" placeholder="Phone number 2" formControlName="phoneNumber2">
                    </div> -->
          <div class="spacer"></div>
          <!-- <h3 class="mb-2">My Documents</h3>
                    <span class="tag_line">You can upload multiple proof of ID, proof of income and bank statement</span>
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-4 document_box">
                                <div class="document_box_inner">
                                    <h5>ID*</h5>
                                    <div class="upload_btn">
                                        
                                        <label for="proof-upload" class="btn btn-bordered">
                                            Upload Proof of ID
                                        </label>
                                        <input id="proof-upload" (change)="uploadUserDocuments($event,0,'arrImgIdProof')" type="file" accept="image/*" />
                                        <a class="info-button" target="_blank" href="https://www.mysocialhousing.co.uk/app-proof-of-id">
                                            <img src="assets/images/information.png" > 
                                            <span>Find out what we can accept as proof of id</span>
                                        </a>
                                    </div>
                                    
                                    <div *ngIf="id_Proof_Ary.length > 0">
                                        <div class="upload_imgsec" *ngFor="let item of id_Proof_Ary; let i = index;">
                                            <div class="upload_img_loop">
                                                <div class="img-up img-con">
                                                    <img [src]="item" (click) = "openImage(template1 ,item)">
                                                    <div class="del-Icon" >
                                                        <span (click)="deleteUserDocuments(i,'arrImgIdProof')">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 document_box">
                                <div class="document_box_inner">
                                    <h5>Income</h5>
                                    <div class="upload_btn">
                                        
                                        <label for="income-upload" class="btn btn-bordered">
                                            Upload Proof of Income*
                                        </label>
                                        <input id="income-upload" (change)="uploadUserDocuments($event,0,'arrImgIncomeProof')" type="file" accept="image/*" />
                                        <a class="info-button" target="_blank" href="https://www.mysocialhousing.co.uk/app-proof-of-income">
                                            <img src="assets/images/information.png" > 
                                            <span>Find out what we can accept as proof of income</span>
                                        </a>
                                    </div>
                                    <div *ngIf="inc_Proof_Ary.length > 0">
                                        <div class="upload_imgsec" *ngFor="let item of inc_Proof_Ary; let i = index;">
                                            <div class="upload_img_loop">
                                                <div class="img-up img-con">
                                                    <img [src]="item" (click) = "openImage(template1 ,item)">
                                                    <div class="del-Icon" >
                                                        <span (click)="deleteUserDocuments(i,'arrImgIncomeProof')">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 document_box">
                                <div class="document_box_inner">
                                    <h5>Statements</h5>
                                    <div class="upload_btn">
                                        
                                        <label for="statement-upload" class="btn btn-bordered">
                                            Upload Proof of Statements*
                                        </label>
                                        <input id="statement-upload" (change)="uploadUserDocuments($event,0,'arrImgBankStatements')" type="file" accept="image/*" />
                                    </div>
                                    <div *ngIf="bank_Proof_Ary.length > 0">
                                        <div class="upload_imgsec" *ngFor="let item of bank_Proof_Ary; let i = index;">
                                            <div class="upload_img_loop">
                                                <div class="img-up img-con">
                                                    <img [src]="item" (click) = "openImage(template1 ,item)">
                                                    <div class="del-Icon">
                                                        <span (click)="deleteUserDocuments(i,'arrImgBankStatements')">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <div class="col-sm-12 text-center save_btns">
            <a class="read-right" (click)="openFile()">
              <span>Read the Right to Rent Guide</span>
            </a>
            <button type="submit" class="btn btn-green">Save Profile</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<button
  id="openModal"
  
  (click)="openModal(template2)"
  style="visibility: hidden"
>
  Open
</button>
<ng-template #template1>
  <div class="modal-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="decline()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="dialog_img_inner">
      <img *ngIf="viewImage" [src]="viewImage" style="min-width: 500px" />
      <img
        *ngIf="!viewImage"
        src="assets/images/bg1.png"
        style="min-width: 500px"
        s
      />
    </div>
  </div>
</ng-template>

<ng-template #template2>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Verify Phone Number</h4>
    <!-- <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">×</span>
    </button> -->
  </div>
  <div class="modal-body">
    <div class="row align-items-center row-height">
      <div class="col-md-7">
        <div *ngIf="!selectDiv">
          <p class="updated-number">{{userDetails.phoneNumber1}}</p>
        </div>
        <div *ngIf="selectDiv">
          <div class="form-group">
            <input
              id="changeNum"
              type="tel"
              class="form-control"
              [(ngModel)]="userDetails.phoneNumber1"
            />
          </div>
        </div>
      </div>
      <div class="col-md-5 text-center">
        <a class="update-nmb" (click)="toggleDiv()">{{this.changeNumBtn}}</a>
      </div>
    </div>
    <div class="form-group">
      <input type="number" id="verifyCode" class="form-control" placeholder="Enter Verification code" [disabled]="buttonDisabled"/>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-primary" (click)="modalRef.hide(); verify();" [disabled]="buttonDisabled">
      Verify
    </button>
  </div>
</ng-template>
