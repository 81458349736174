
<section class="banner listing_banner bg" >
    <h2 *ngIf="listName=='Properties'"> <strong>Homes</strong> Available Now </h2>
    <h2 *ngIf="listName=='shortLists'"> <strong>Shorlisted Properties</strong> </h2>
    <h2 *ngIf="listName=='settings'"> <strong>Settings</strong> </h2>
    <h2 *ngIf="listName=='notifications'"> <strong>Notifications</strong> </h2>
    <h2 *ngIf="listName=='profile'"> <strong>Profile</strong> </h2>
</section>

<div class="heading filter_box">
    <div class="container">
        <div class="results" routerLink="/user/properties" routerLinkActive="active">Homes available</div>
        <div class="results" routerLink="/user/shortlisted" routerLinkActive="active">Shortlisted</div>
        <div class="results" routerLink="/user/edit-profile" routerLinkActive="active">Profile</div>
        <div class="results" routerLink="/user/notifications" routerLinkActive="active">Notifications</div>
        <div class="results" routerLink="/user/settings" routerLinkActive="active">Settings</div>
    </div>
</div>

<div class="heading filter_box search--box" *ngIf="listName=='Properties' || listName=='shortLists'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6 text-left" *ngIf="listName=='shortLists'">
                <div class="result--count"><span>Shortlisted Properties</span></div>
            </div>
            <div class="col-md-6" [ngClass]="{'text-md-right': listName=='shortLists', 'text-left': listName=='Properties'}">
                <div class="result--count">{{properties}} <span>Results</span></div>
            </div>
            <div class="col-md-6" *ngIf="listName=='Properties'">                
                <div class="filter">
                    <div class="searCH_box">
                        <input [(ngModel)]="searchProperty" (keyup)="sendSearchText()" type="text" placeholder="Search">
                        <button *ngIf="searchProperty" (click)="searchProperty = null;sendSearchText()"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="filter_btn" data-toggle="collapse" data-target="#filter_box_dropdown">
                        <span>
                            <img src="assets/images/filter_blk.svg"> Filter 
                        </span>
                        <img src="assets/images/arrow-blk.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="filter_box_dropdown" class="collapse filter_box_dropdown">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-6 filter_left">
                <h6>Show closet property first
                    <div class="custom-control custom-switch">
                        <input [checked]="filters.closestFirst" (change)="onchangeCheckbox($event)" type="checkbox" class="custom-control-input" id="customSwitch1">
                        <label class="custom-control-label" for="customSwitch1"></label>
                    </div>
                </h6>
                <h6>Nearest to city:
                    <div class="custom-control custom-switch w-100">
                        <ng-select [(ngModel)]="selectedCity"  name="city" [items]="areaOfInterestList"
                                    placeholder="Select city"
                                    bindLabel="name" 
                                    (change)="onChange(selectedCity)">
                        </ng-select>
                    </div>
                </h6>
                <!-- <h6>Show closet properly first</h6> -->
                <form>
                    <div class="form-group">
                        <h6 for="formControlRange">How far from you? <strong>{{filters.miles}} miles</strong></h6>
                        <!-- <input type="range" min="1" max="100" value="50" class="slider" id="myRange"> -->
                        <ng5-slider [(value)]="filters.miles" [options]="options" (userChangeEnd)="onChangeMiles($event)"></ng5-slider>
                    </div>
                </form>
            </div>
            <div class=" col-md-6 col-sm-6 filter_right">
                <h6>Minimum Beds</h6>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="0" (change)="onChangeBedType()" [disabled]="isDisabled(0,'min')" type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio1">Studio</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="1" (change)="onChangeBedType()" [disabled]="isDisabled(1,'min')" type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio2">1 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="2" (change)="onChangeBedType()" [disabled]="isDisabled(2,'min')" type="radio" id="customRadio3" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio3">2 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="3" (change)="onChangeBedType()" [disabled]="isDisabled(3,'min')" type="radio" id="customRadio4" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio4">3 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="4" (change)="onChangeBedType()" [disabled]="isDisabled(4,'min')" type="radio" id="customRadio5" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio5">4 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="5" (change)="onChangeBedType()" [disabled]="isDisabled(5,'min')" type="radio" id="customRadio6" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio6">5 Bed</label>
                </div>
                <!-- <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="6" (change)="onChangeBedType()" [disabled]="isDisabled(6,'min')" type="radio" id="customRadio7" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio7">6 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="7" (change)="onChangeBedType()" [disabled]="isDisabled(7,'min')" type="radio" id="customRadio8" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio8">7 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.min" [value]="8" (change)="onChangeBedType()" [disabled]="isDisabled(8,'min')" type="radio" id="customRadio9" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio9">8 Bed</label>
                </div> -->

                <h6 class="mt-2">Maximum Beds</h6>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="0" (change)="onChangeBedType()" [disabled]="isDisabled(0,'max')" type="radio" id="customRadio11" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio11">Studio</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="1" (change)="onChangeBedType()" [disabled]="isDisabled(1,'max')" type="radio" id="customRadio12" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio12">1 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="2" (change)="onChangeBedType()" [disabled]="isDisabled(2,'max')" type="radio" id="customRadio13" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio13">2 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="3" (change)="onChangeBedType()" [disabled]="isDisabled(3,'max')" type="radio" id="customRadio14" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio14">3 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="4" (change)="onChangeBedType()" [disabled]="isDisabled(4,'max')" type="radio" id="customRadio15" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio15">4 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="5" (change)="onChangeBedType()" [disabled]="isDisabled(5,'max')" type="radio" id="customRadio16" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio16">5 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="6" (change)="onChangeBedType()" [disabled]="isDisabled(6,'max')" type="radio" id="customRadio17" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio17">6 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="7" (change)="onChangeBedType()" [disabled]="isDisabled(7,'max')" type="radio" id="customRadio18" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio18">7 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="8" (change)="onChangeBedType()" [disabled]="isDisabled(8,'max')" type="radio" id="customRadio19" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio19">8 Bed</label>
                </div>
                <div class="custom-control custom-radio btn-radio">
                    <input [(ngModel)]="filters.bedroomFilter.max" [value]="9" (change)="onChangeBedType()" [disabled]="isDisabled(9,'max')" type="radio" id="customRadio19" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio19">9 Bed</label>
                </div>

                <div class="text-right col-sm-12 mt-3">
                    <button class="btn clear_btn" (click)="onClickReset()" data-toggle="collapse" data-target="#filter_box_dropdown">Clear all filter</button>
                    <button class="btn btn-primary" (click)="onClickSave()" data-toggle="collapse" data-target="#filter_box_dropdown">Save</button>
                </div>

            </div>
        </div>
    </div>
</section>