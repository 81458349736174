<header>
    <div class="container">
        <div class="header_inner">
            <div class="logo">
                <a routerLink="/"><img src="assets/images/live-tech-logo.svg"></a>
            </div>
            <div class="btns">
                <a *ngIf="'/application-form' !== showButtons()" class="btn mr-3" routerLink="/application-form">Complete Form</a>
                <a *ngIf="'/login' !== showButtons()" routerLink="/login" class=" btn mr-3 "><img src="assets/images/user.svg"> Sign In</a>
                <a *ngIf="'/register' !== showButtons()" class="btn btn-white" routerLink="/register">Create an Account</a>
            </div>
        </div>
    </div>
</header>