import { Component, OnInit, TemplateRef,   } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/core/services/api-service';
import { AlertNotification } from 'src/app/core/alertNotification/alertNotification';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {Location} from '@angular/common';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

import Swal from 'sweetalert2';
import { FirebaseQueryService } from 'src/app/core/services/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {

  propertyDetails = null;
  userDetails = null;
  viewImage : any
  modalRef: BsModalRef;

  msrc :any;


  constructor(private modalService: BsModalService,
    private _location: Location,
    private router : Router,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    public  sanitizer: DomSanitizer,
    private firebaseservice : FirebaseQueryService,
    private loaderService: NgxSpinnerService,

  ) {
    if(localStorage.getItem("propertyDetail")) {
      this.propertyDetails = JSON.parse(localStorage.getItem("propertyDetail"));
      this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
      let latitude = this.propertyDetails.lat;
      let longitude = this.propertyDetails.lng
      this.msrc = `https://www.google.com/maps?q=${latitude},${longitude}&output=embed`
    }else{
      this.router.navigate(['/user/properties']);
    }
  }

  ngOnInit(): void {
    // console.log(this.propertyDetails);
    window.scrollTo(0,0);
    console.log(this._location);
  }
  
  checkImageUrl(url) {
    if(url.includes("https") || url.includes("http")) {
      return url
    } else{
      return('assets/images/bg1.png');
    }
  }
  
  openImage(template1: TemplateRef<any> , url){
    if(url.includes("https") || url.includes("http")) {
      this.viewImage = url
      // return url
    }
    this.modalRef = this.modalService.show(template1, {class: 'modal-show-image'});
    
  }


  shortListOrFavorite(shortList){
    if( this.modalRef){
      this.modalRef.hide();
    }
    const body = {
      email : this.userDetails.email,
      id: this.propertyDetails.id,
      shortlisted: shortList
    }
    this.apiservice.postRequest(`addfavorite.php`, body).subscribe((response: any) => {
      let msg = shortList?'Property shortlisted successfully':'Property added to favorites successfully';
      this.alertnotification.successAlert(msg);
    },
      error => {
        // console.log(error.error);
        this.alertnotification.errorAlert(error.error.message);
      });
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  decline(): void {
    this.modalRef.hide();
  }

  backToPropertyList(){
    if(localStorage.getItem("pageInfo")) {
      let backUrl =  JSON.parse(localStorage.getItem("pageInfo"));
      this.router.navigate([`/user/${backUrl.fromPage}`])
    } else{
      this.router.navigate(['/user/properties'])
    }
  }


  addShortListed(shortList){
    Swal.fire({
      title: 'Do you want to Shortlist this property?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    })
    .then(async(result) => {
      if(result.value == true){
        // localStorage.clear();
        // this.alertnotification.successAlert('Logout Successfully');
        // this.router.navigate(['/']);
        try {
          let notDelted = await this.getUserDetails();
          if(notDelted){
            if(this.userDetails.isProfileSaved){
              if(this.propertyDetails.roomtype>=2 && (!this.userDetails.firstNameFC || !this.userDetails.lastNameFC)){
                Swal.fire({
                  title: `You have not provided any family composition details, you can only register interest in properties with 1 bedroom.`,
                  icon: 'warning',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'ok'
                }).then((result) => {
                  
                });
              } else{
                const body = {
                  email : this.userDetails.email,
                  id: this.propertyDetails.id,
                  shortlisted: shortList
                }
                this.apiservice.postRequest(`addfavorite.php`, body).subscribe((response: any) => {
                  if(response && response.error){
                    Swal.fire({
                      title: `You have previously shown interest in this property, multiple registration is not required.`,
                      icon: 'warning',
                      confirmButtonColor: '#3085d6',
                      confirmButtonText: 'ok'
                    }).then((result) => {
                      
                    });
                  } else{

                    let allInfo = this.propertyDetails?.title.toLowerCase() + this.propertyDetails?.desc.toLowerCase() + this.propertyDetails?.property_address.toLowerCase() + this.propertyDetails?.feature.toLowerCase()
                    allInfo = allInfo.toLowerCase();
                    if(allInfo.includes(55)){
                      Swal.fire({
                        title: `We think this property might be suitable only for people aged 55 and above, please read the description before registering interest. Continue?`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Continue'
                      })
                      .then((result) => {
                        if(result.value == true){
                          let msg = shortList?'Property shortlisted successfully':'Property added to favorites successfully';
                          this.alertnotification.successAlert(msg);
                        }else{
                          return;
                        }
                      });
                    }else{
                      let msg = shortList?'Property shortlisted successfully':'Property added to favorites successfully';
                      this.alertnotification.successAlert(msg);
                    }
                  }
                },
                  error => {
                    // console.log(error.error);
                    this.alertnotification.errorAlert(error.error.message);
                });
              }
            }else{
              Swal.fire({
                title: `Please complete your profile in order to register your interest in this property.`,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ok'
              }).then((result) => {
                
              });
            }
          }
        } catch (error) {
          this.loaderService.hide();
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        
      }else{
        return;
      }
    });
  }

  getUserDetails() {
    var records1 = [];
    return new Promise((resolve,reject) => {
      this.firebaseservice.getUserDetail(this.userDetails.userId).then((querySnapshot)=> {
        querySnapshot.forEach(function(doc) {
          var data = doc.data();
          data['id'] = doc.id
          records1.push(data);
        })
        if(records1 && records1.length && records1[0].deleted){
          this.loaderService.hide();
          Swal.fire({
            title: `Your account has been deleted because you refused a property. We have a one-offer policy as stated in our terms and conditions`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ok'
          })
          .then((result) => {
            reject(false);
          });
        } else{
          this.loaderService.hide();
          resolve(true);
        }
      }).catch((error)=> {
        this.loaderService.hide();
        console.log("Error getting documents: ", error);
        reject(false);
      })
    })
  }


}
