import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserComponent } from './user.component';
import { PropertyLisitngComponent } from './property-lisitng/property-lisitng.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ShortlistedComponent } from './shortlisted/shortlisted.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';

import { CanActivateAdmin } from '../core/guards/loginguard';
import {ApplicationformComponent} from '../layout/applicationform/applicationform.component';

const routes: Routes = [
  {
    path : "user" , 
    component : UserComponent ,
    canActivate : [CanActivateAdmin],
    children : [
      { path : "properties" , component : PropertyLisitngComponent},
      { path : "shortlisted" , component : ShortlistedComponent},
      { path : "settings" , component : SettingsComponent },
      { path : "notifications" , component : NotificationsComponent},
      { path : "property-details" , component : PropertyDetailsComponent},
      { path : "edit-profile" , component : EditProfileComponent}
      // { path : "/properties" , }
    ]
  },{
    path : "application-form" ,
    component : ApplicationformComponent ,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
