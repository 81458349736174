import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'; 
import Swal from 'sweetalert2'
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AlertNotification {

  constructor( ) { }
  
  successAlert(message){
    return Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        // text : message,
        showConfirmButton: false,
        timer: 3000
    })
  }

  errorAlert(message){
    return Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        // text : message,
        showConfirmButton: false,
        timer: 3000
    })
  }

  logout(message){
    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 3000
    })
  }
}
