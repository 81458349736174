import { FormGroup, Validators } from "@angular/forms";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";


/**
 * @purpose custom validator to check that two fields match that must be same
 * @method MustMatch
 * @param control
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}


export const PostalCodeValidator = function(
  control: AbstractControl
): ValidationErrors | null {
  let value: string = control.value?.replace(/\s+/g, '') || "";
  let validationFalse = false;
  var errors = "<ul>";
  if (!value) {
    validationFalse = true;
    errors += `<li>Valid Postcode is required</li>`;
  }

  let upperCaseCharacters = /[a-zA-Z]+/g;
  let numberCharacters = /[0-9]+/g;
  if (value.length > 0 && upperCaseCharacters.test(value) === false) {
    validationFalse = true;
    errors += `<li> Valid Postcode is required. Postcode example: M5 7RT </li>`;
  }else if (value.length > 0 && numberCharacters.test(value) === false) {
    validationFalse = true;
    errors += `<li>  Valid Postcode is required. Postcode example: M5 7RT </li>`;
  }else if (value.length > 0 && value.length < 5) {
    validationFalse = true;
    errors += `<li>  Postcode should be atleast 5 characters long </li>`;
  }

  // let whitespace = /\s/;
  // if (whitespace.test(value) === true) {
  //   validationFalse = true;
  //   errors += `<li>  Postcode should not contain whitespace  </li>`;
  // }


  

  if (validationFalse) {
    errors += "</ul>";
    return { postalCodeValidator: errors };
  }

  return null;
};

export const PhoneNumberValidator = function(
  control: AbstractControl
): ValidationErrors | null {
    
    let value: string = control.value?.replace(/\s+/g, '') || "";
    let validationFalse = false;
    var errors = "<ul>";
    if (!value) {
      validationFalse = true;
      errors += `<li> Phone number is required </li>`;
    }

    let upperCaseCharacters = /[a-zA-Z]+/g;
    if (value.length > 0 && upperCaseCharacters.test(value) === true) {
      validationFalse = true;
      errors += `<li>  Phone number cannot contain letters </li>`;
    }else if (value.length > 0 && (value.length < 10 )) {
      validationFalse = true;
      errors += `<li>  Phone number must be at least 10 digits </li>`;
    }

    if (validationFalse) {      
      errors += "</ul>";
      return { PhoneNumberValidator: errors };
    }

    return null;
};

export const EmailFieldValidator = function(
  control: AbstractControl
) : ValidationErrors | null{

  let value: string = control.value?.replace(/\s+/g, '') || "";
    let validationFalse = false;
    var errors = "<ul>";
    if (!value) {
      validationFalse = true;
      errors += `<li> Email is required </li>`;
    }

  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (value.length > 0 && re.test(value) === false) {
    validationFalse = true;
    errors += `<li> Enter valid email</li>`;
  }

  if (validationFalse) {
    console.log(value)
    errors += "</ul>";
    return { EmailFieldValidator: errors };
  }

  return null;
}

export const onlyAlphabets = function(
  control: AbstractControl
) : ValidationErrors | null{

  let value: string = control.value?.replace(/\s+/g, '') || "";
    let validationFalse = false;
    var errors = "<ul>";
    if (!value) {
      validationFalse = true;
      // errors += `<li> Field is required </li>`;
    }

  const re =/^[a-zA-Z ]*$/;

  if (value.length > 0 && re.test(value) === false) {
    validationFalse = true;
    errors += `<li> Enter only english characters</li>`;
  }

  if (validationFalse) {
    console.log(value)
    errors += "</ul>";
    return { onlyAlphabets: errors };
  }

  return null;
}
