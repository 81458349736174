import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { PropertyLisitngComponent } from './property-lisitng/property-lisitng.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserFooterComponent } from './user-footer/user-footer.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ShortlistedComponent } from './shortlisted/shortlisted.component';
import { TabsHeadersComponent } from './tabs-headers/tabs-headers.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './notifications/notifications.component';


@NgModule({
  declarations: [
    UserComponent, 
    PropertyLisitngComponent, 
    UserHeaderComponent, 
    UserFooterComponent, PropertyDetailsComponent, EditProfileComponent, ShortlistedComponent, TabsHeadersComponent, SettingsComponent, NotificationsComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    SharedModule,
  ]
})
export class UserModule { }
