
<app-tabs-headers [properties]="shortListArray?.length" [listName]="'shortLists'"  ></app-tabs-headers>

<section class="listing">
    <div class="container">
        <ng-template *ngIf="shortListArray?.length != 0" ngFor let-property [ngForOf]="shortListArray | paginate: { itemsPerPage: 20, currentPage: p }" let-i="index">
            <div class="row wh_listing" [id]="i">
                <div class="col-md-4 col-sm-4 listing_img">
                    <div class="listing_img_inner">
                        <img class="fav" src="assets/images/heart-full.png" *ngIf="property.shortlisted === 'false'">
                        <img *ngIf="property.image" [src]="checkImageUrl(property.image)" (error)="property.image = 'assets/images/bg1.png'">
                        <img *ngIf="!property.image" src="assets/images/bg1.png">
                        <div class="over55" *ngIf="property.shortlisted && property.shortlisted == 'true'">
                            <span>Shorlisted</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 col-sm-8 listing_text">
                    <span>{{property.distance}} miles away</span>
                    <h4>
                        <a href="javascript:void(0)" (click)="viewProperty(property, i)">{{property.title}}</a>
                    </h4>
                    <h5>{{property.roomtype && property.roomtype > 0 ? property.roomtype + ' Bedroom' : 'Studio'}}</h5>
                    <p>{{property.desc | limitTo : '140'}}</p>
                    <a class="btn btn-green" (click)="viewProperty(property, i)">View More</a>
                </div>
            </div>
        </ng-template>
        <div class="row wh_listing" *ngIf="shortListArray?.length == 0">
            <p style="margin: 0 auto;">No Document Found</p>
        </div>
        <div *ngIf="shortListArray?.length > 0" class="pagination float-right">
            <pagination-controls (click)="clickPagination()" (pageChange)="p = $event" directionLinks="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
        </div>
    </div>
</section>

