<footer>
    <div class="footer_top">
        <div class="container">
            <p>MySocialHousing app is now available to download on the Apple App Store and Google Play Store. Make sure you don't miss out on a property and a new home for you and your family.</p>
            <div class="app_btn">
                <a href="https://apps.apple.com/gb/app/my-social-housing/id1508581706" target="_blank" class="mr-2"><img src="assets/images/btn-appstore.svg"></a>
                <a href="https://play.google.com/store/apps/details?id=uk.co.mysocialhousing&hl=en_GB&gl=US" target="_blank"><img src="assets/images/btn-googleplay.svg"></a>
            </div>
            <div class="contact_us">
                <ul>
                    <li>CONTACT US </li>
                    <li><img src="assets/images/email.svg"> Email: info@mysocialhousing.co.uk </li>
                    <li><img src="assets/images/phone.svg"> Phone: 01895 807447</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="copyright">
        <div class="container">
            <span>Copyright © MySocialHousing 2021. All Rights Reserved.</span>
            <span class="address">Contact Address - The Atrium, 1 Harefield Rd, Uxbridge, UB8 1EX</span>
        </div>
    </div>
</footer>