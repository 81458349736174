import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import * as firebase from 'firebase';
// import * as admin from "firebase-admin";
import { Subject, BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
// import { NgxSpinnerService } from 'ngx-spinner';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})

export class FirebaseQueryService {

  DB = firebase.firestore(); 

  constructor(
    private db: AngularFirestore,
    private loaderService: NgxSpinnerService
  ) {}
  
  signInUser(email,password){
    this.loaderService.show();
    return firebase.auth().signInWithEmailAndPassword(email,password)
  }

  createUser(email,password){
    this.loaderService.show();
    return firebase.auth().createUserWithEmailAndPassword(email,password)
  }

  registerUser(data) { 
    this.loaderService.show();
    return this.DB.collection('users').add(data);
  }

  currentUser(){
    this.loaderService.show();
    return firebase.auth().currentUser;
  }

  getUserDetail(id) {
    this.loaderService.show();
    return this.DB.collection('users').where("userId","==",id).get();
  }

  passwordreset(email){
    this.loaderService.show();
    return firebase.auth().sendPasswordResetEmail(email)
  }

  uploadFile(file,childName) {
    console.log(childName);
    this.loaderService.show();
    return firebase.storage().ref().child(childName).put(file);
  }

  updateUserRecords(id,data) {
    this.loaderService.show();
    return this.DB.collection("users").doc(id).update(data);
  }
  createPublicRecord(data){
    this.loaderService.show();
    return this.DB.collection("users").add(data);
  }
}
